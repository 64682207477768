import $ from "jquery";

$(function () {
  $(".selectable-list").each(function () {
    const checkboxes = $(this).find(".select-item").not("[disabled]");
    const checkedItems = () =>
      checkboxes.get().filter((c: HTMLInputElement) => c.checked);
    const uncheckedItems = () =>
      checkboxes.get().filter((c: HTMLInputElement) => !c.checked);
    const checkedItemIds = () =>
      checkedItems().map((c) => $(c).data("id") as string);
    const groupSelectors = $(this).find(".select-group").not("[disabled]");

    const groupSelects = function (groupId: string) {
      return checkboxes.get().filter((c) => $(c).data("group-id") == groupId);
    };

    // Select all
    $(this)
      .find(".select-all")
      .click(function () {
        const checked = uncheckedItems().length > 0;
        checkboxes.prop("checked", checked);
        groupSelectors.prop("checked", checked);
        return false;
      });

    // Forms
    $(this)
      .find("form")
      .submit(function () {
        $(this).find(".ids").val(checkedItemIds().join(","));
      });

    // Group select
    groupSelectors.change(function () {
      const selected = (this as HTMLInputElement).checked;
      $(groupSelects($(this).data("group-id") as string)).each(function () {
        $(this).prop("checked", selected);
      });
    });

    $(this)
      .find(".group-selectable")
      .change(function () {
        const id = $(this).data("group-id") as string;
        const selectedAll = !(
          groupSelects(id).filter((c: HTMLInputElement) => !c.checked).length >
          0
        );

        groupSelectors
          .get()
          .filter((c) => $(c).data("group-id") == id)
          .map((c) => $(c).prop("checked", selectedAll));
      });
  });
});

export type PostalCodes = Record<string, string>;

const postalCodes: PostalCodes = {
  "0001": "Oslo",
  "0010": "Oslo",
  "0015": "Oslo",
  "0018": "Oslo",
  "0021": "Oslo",
  "0024": "Oslo",
  "0025": "Oslo",
  "0026": "Oslo",
  "0028": "Oslo",
  "0030": "Oslo",
  "0031": "Oslo",
  "0032": "Oslo",
  "0033": "Oslo",
  "0034": "Oslo",
  "0037": "Oslo",
  "0040": "Oslo",
  "0045": "Oslo",
  "0046": "Oslo",
  "0047": "Oslo",
  "0048": "Oslo",
  "0050": "Oslo",
  "0051": "Oslo",
  "0055": "Oslo",
  "0060": "Oslo",
  "0101": "Oslo",
  "0102": "Oslo",
  "0103": "Oslo",
  "0104": "Oslo",
  "0105": "Oslo",
  "0106": "Oslo",
  "0107": "Oslo",
  "0109": "Oslo",
  "0110": "Oslo",
  "0111": "Oslo",
  "0112": "Oslo",
  "0113": "Oslo",
  "0114": "Oslo",
  "0115": "Oslo",
  "0116": "Oslo",
  "0117": "Oslo",
  "0118": "Oslo",
  "0119": "Oslo",
  "0120": "Oslo",
  "0121": "Oslo",
  "0122": "Oslo",
  "0123": "Oslo",
  "0124": "Oslo",
  "0125": "Oslo",
  "0128": "Oslo",
  "0129": "Oslo",
  "0130": "Oslo",
  "0131": "Oslo",
  "0132": "Oslo",
  "0133": "Oslo",
  "0134": "Oslo",
  "0135": "Oslo",
  "0136": "Oslo",
  "0138": "Oslo",
  "0139": "Oslo",
  "0150": "Oslo",
  "0151": "Oslo",
  "0152": "Oslo",
  "0153": "Oslo",
  "0154": "Oslo",
  "0155": "Oslo",
  "0157": "Oslo",
  "0158": "Oslo",
  "0159": "Oslo",
  "0160": "Oslo",
  "0161": "Oslo",
  "0162": "Oslo",
  "0164": "Oslo",
  "0165": "Oslo",
  "0166": "Oslo",
  "0167": "Oslo",
  "0168": "Oslo",
  "0169": "Oslo",
  "0170": "Oslo",
  "0171": "Oslo",
  "0172": "Oslo",
  "0173": "Oslo",
  "0174": "Oslo",
  "0175": "Oslo",
  "0176": "Oslo",
  "0177": "Oslo",
  "0178": "Oslo",
  "0179": "Oslo",
  "0180": "Oslo",
  "0181": "Oslo",
  "0182": "Oslo",
  "0183": "Oslo",
  "0184": "Oslo",
  "0185": "Oslo",
  "0186": "Oslo",
  "0187": "Oslo",
  "0188": "Oslo",
  "0190": "Oslo",
  "0191": "Oslo",
  "0192": "Oslo",
  "0193": "Oslo",
  "0194": "Oslo",
  "0195": "Oslo",
  "0196": "Oslo",
  "0198": "Oslo",
  "0201": "Oslo",
  "0202": "Oslo",
  "0203": "Oslo",
  "0204": "Oslo",
  "0207": "Oslo",
  "0208": "Oslo",
  "0211": "Oslo",
  "0212": "Oslo",
  "0213": "Oslo",
  "0214": "Oslo",
  "0215": "Oslo",
  "0216": "Oslo",
  "0217": "Oslo",
  "0218": "Oslo",
  "0230": "Oslo",
  "0240": "Oslo",
  "0244": "Oslo",
  "0247": "Oslo",
  "0250": "Oslo",
  "0251": "Oslo",
  "0252": "Oslo",
  "0253": "Oslo",
  "0254": "Oslo",
  "0255": "Oslo",
  "0256": "Oslo",
  "0257": "Oslo",
  "0258": "Oslo",
  "0259": "Oslo",
  "0260": "Oslo",
  "0262": "Oslo",
  "0263": "Oslo",
  "0264": "Oslo",
  "0265": "Oslo",
  "0266": "Oslo",
  "0267": "Oslo",
  "0268": "Oslo",
  "0270": "Oslo",
  "0271": "Oslo",
  "0272": "Oslo",
  "0273": "Oslo",
  "0274": "Oslo",
  "0275": "Oslo",
  "0276": "Oslo",
  "0277": "Oslo",
  "0278": "Oslo",
  "0279": "Oslo",
  "0280": "Oslo",
  "0281": "Oslo",
  "0282": "Oslo",
  "0283": "Oslo",
  "0284": "Oslo",
  "0286": "Oslo",
  "0287": "Oslo",
  "0301": "Oslo",
  "0302": "Oslo",
  "0303": "Oslo",
  "0304": "Oslo",
  "0305": "Oslo",
  "0306": "Oslo",
  "0307": "Oslo",
  "0308": "Oslo",
  "0309": "Oslo",
  "0311": "Oslo",
  "0313": "Oslo",
  "0314": "Oslo",
  "0315": "Oslo",
  "0316": "Oslo",
  "0317": "Oslo",
  "0318": "Oslo",
  "0319": "Oslo",
  "0323": "Oslo",
  "0330": "Oslo",
  "0340": "Oslo",
  "0349": "Oslo",
  "0350": "Oslo",
  "0351": "Oslo",
  "0352": "Oslo",
  "0353": "Oslo",
  "0354": "Oslo",
  "0355": "Oslo",
  "0356": "Oslo",
  "0357": "Oslo",
  "0358": "Oslo",
  "0359": "Oslo",
  "0360": "Oslo",
  "0361": "Oslo",
  "0362": "Oslo",
  "0363": "Oslo",
  "0364": "Oslo",
  "0365": "Oslo",
  "0366": "Oslo",
  "0367": "Oslo",
  "0368": "Oslo",
  "0369": "Oslo",
  "0370": "Oslo",
  "0371": "Oslo",
  "0372": "Oslo",
  "0373": "Oslo",
  "0374": "Oslo",
  "0375": "Oslo",
  "0376": "Oslo",
  "0377": "Oslo",
  "0378": "Oslo",
  "0379": "Oslo",
  "0380": "Oslo",
  "0381": "Oslo",
  "0382": "Oslo",
  "0383": "Oslo",
  "0401": "Oslo",
  "0402": "Oslo",
  "0403": "Oslo",
  "0404": "Oslo",
  "0405": "Oslo",
  "0406": "Oslo",
  "0408": "Oslo",
  "0409": "Oslo",
  "0410": "Oslo",
  "0411": "Oslo",
  "0412": "Oslo",
  "0413": "Oslo",
  "0415": "Oslo",
  "0421": "Oslo",
  "0422": "Oslo",
  "0423": "Oslo",
  "0424": "Oslo",
  "0440": "Oslo",
  "0441": "Oslo",
  "0442": "Oslo",
  "0445": "Oslo",
  "0450": "Oslo",
  "0451": "Oslo",
  "0452": "Oslo",
  "0454": "Oslo",
  "0455": "Oslo",
  "0456": "Oslo",
  "0457": "Oslo",
  "0458": "Oslo",
  "0459": "Oslo",
  "0460": "Oslo",
  "0461": "Oslo",
  "0462": "Oslo",
  "0463": "Oslo",
  "0464": "Oslo",
  "0465": "Oslo",
  "0467": "Oslo",
  "0468": "Oslo",
  "0469": "Oslo",
  "0470": "Oslo",
  "0472": "Oslo",
  "0473": "Oslo",
  "0474": "Oslo",
  "0475": "Oslo",
  "0476": "Oslo",
  "0477": "Oslo",
  "0478": "Oslo",
  "0479": "Oslo",
  "0480": "Oslo",
  "0481": "Oslo",
  "0482": "Oslo",
  "0483": "Oslo",
  "0484": "Oslo",
  "0485": "Oslo",
  "0486": "Oslo",
  "0487": "Oslo",
  "0488": "Oslo",
  "0489": "Oslo",
  "0490": "Oslo",
  "0491": "Oslo",
  "0492": "Oslo",
  "0493": "Oslo",
  "0494": "Oslo",
  "0495": "Oslo",
  "0496": "Oslo",
  "0501": "Oslo",
  "0502": "Oslo",
  "0503": "Oslo",
  "0504": "Oslo",
  "0505": "Oslo",
  "0506": "Oslo",
  "0507": "Oslo",
  "0508": "Oslo",
  "0509": "Oslo",
  "0510": "Oslo",
  "0511": "Oslo",
  "0512": "Oslo",
  "0513": "Oslo",
  "0515": "Oslo",
  "0516": "Oslo",
  "0517": "Oslo",
  "0518": "Oslo",
  "0520": "Oslo",
  "0540": "Oslo",
  "0550": "Oslo",
  "0551": "Oslo",
  "0552": "Oslo",
  "0553": "Oslo",
  "0554": "Oslo",
  "0555": "Oslo",
  "0556": "Oslo",
  "0557": "Oslo",
  "0558": "Oslo",
  "0559": "Oslo",
  "0560": "Oslo",
  "0561": "Oslo",
  "0562": "Oslo",
  "0563": "Oslo",
  "0564": "Oslo",
  "0565": "Oslo",
  "0566": "Oslo",
  "0567": "Oslo",
  "0568": "Oslo",
  "0569": "Oslo",
  "0570": "Oslo",
  "0571": "Oslo",
  "0572": "Oslo",
  "0573": "Oslo",
  "0574": "Oslo",
  "0575": "Oslo",
  "0576": "Oslo",
  "0577": "Oslo",
  "0578": "Oslo",
  "0579": "Oslo",
  "0580": "Oslo",
  "0581": "Oslo",
  "0582": "Oslo",
  "0583": "Oslo",
  "0584": "Oslo",
  "0585": "Oslo",
  "0586": "Oslo",
  "0587": "Oslo",
  "0588": "Oslo",
  "0589": "Oslo",
  "0590": "Oslo",
  "0591": "Oslo",
  "0592": "Oslo",
  "0593": "Oslo",
  "0594": "Oslo",
  "0595": "Oslo",
  "0596": "Oslo",
  "0597": "Oslo",
  "0598": "Oslo",
  "0601": "Oslo",
  "0602": "Oslo",
  "0603": "Oslo",
  "0604": "Oslo",
  "0605": "Oslo",
  "0606": "Oslo",
  "0607": "Oslo",
  "0608": "Oslo",
  "0609": "Oslo",
  "0611": "Oslo",
  "0612": "Oslo",
  "0613": "Oslo",
  "0614": "Oslo",
  "0615": "Oslo",
  "0616": "Oslo",
  "0617": "Oslo",
  "0618": "Oslo",
  "0619": "Oslo",
  "0620": "Oslo",
  "0621": "Oslo",
  "0622": "Oslo",
  "0623": "Oslo",
  "0624": "Oslo",
  "0626": "Oslo",
  "0650": "Oslo",
  "0651": "Oslo",
  "0652": "Oslo",
  "0653": "Oslo",
  "0654": "Oslo",
  "0655": "Oslo",
  "0656": "Oslo",
  "0657": "Oslo",
  "0658": "Oslo",
  "0659": "Oslo",
  "0660": "Oslo",
  "0661": "Oslo",
  "0662": "Oslo",
  "0663": "Oslo",
  "0664": "Oslo",
  "0665": "Oslo",
  "0666": "Oslo",
  "0667": "Oslo",
  "0668": "Oslo",
  "0669": "Oslo",
  "0670": "Oslo",
  "0671": "Oslo",
  "0672": "Oslo",
  "0673": "Oslo",
  "0674": "Oslo",
  "0675": "Oslo",
  "0676": "Oslo",
  "0677": "Oslo",
  "0678": "Oslo",
  "0679": "Oslo",
  "0680": "Oslo",
  "0681": "Oslo",
  "0682": "Oslo",
  "0683": "Oslo",
  "0684": "Oslo",
  "0685": "Oslo",
  "0686": "Oslo",
  "0687": "Oslo",
  "0688": "Oslo",
  "0689": "Oslo",
  "0690": "Oslo",
  "0691": "Oslo",
  "0692": "Oslo",
  "0693": "Oslo",
  "0694": "Oslo",
  "0701": "Oslo",
  "0702": "Oslo",
  "0705": "Oslo",
  "0710": "Oslo",
  "0712": "Oslo",
  "0750": "Oslo",
  "0751": "Oslo",
  "0752": "Oslo",
  "0753": "Oslo",
  "0754": "Oslo",
  "0755": "Oslo",
  "0756": "Oslo",
  "0757": "Oslo",
  "0758": "Oslo",
  "0760": "Oslo",
  "0763": "Oslo",
  "0764": "Oslo",
  "0765": "Oslo",
  "0766": "Oslo",
  "0767": "Oslo",
  "0768": "Oslo",
  "0770": "Oslo",
  "0771": "Oslo",
  "0772": "Oslo",
  "0773": "Oslo",
  "0774": "Oslo",
  "0775": "Oslo",
  "0776": "Oslo",
  "0777": "Oslo",
  "0778": "Oslo",
  "0779": "Oslo",
  "0781": "Oslo",
  "0782": "Oslo",
  "0783": "Oslo",
  "0784": "Oslo",
  "0785": "Oslo",
  "0786": "Oslo",
  "0787": "Oslo",
  "0788": "Oslo",
  "0789": "Oslo",
  "0790": "Oslo",
  "0791": "Oslo",
  "0801": "Oslo",
  "0805": "Oslo",
  "0806": "Oslo",
  "0807": "Oslo",
  "0840": "Oslo",
  "0850": "Oslo",
  "0851": "Oslo",
  "0852": "Oslo",
  "0853": "Oslo",
  "0854": "Oslo",
  "0855": "Oslo",
  "0856": "Oslo",
  "0857": "Oslo",
  "0858": "Oslo",
  "0860": "Oslo",
  "0861": "Oslo",
  "0862": "Oslo",
  "0863": "Oslo",
  "0864": "Oslo",
  "0870": "Oslo",
  "0871": "Oslo",
  "0872": "Oslo",
  "0873": "Oslo",
  "0874": "Oslo",
  "0875": "Oslo",
  "0876": "Oslo",
  "0877": "Oslo",
  "0880": "Oslo",
  "0881": "Oslo",
  "0882": "Oslo",
  "0883": "Oslo",
  "0884": "Oslo",
  "0890": "Oslo",
  "0891": "Oslo",
  "0901": "Oslo",
  "0902": "Oslo",
  "0903": "Oslo",
  "0904": "Oslo",
  "0905": "Oslo",
  "0907": "Oslo",
  "0908": "Oslo",
  "0913": "Oslo",
  "0914": "Oslo",
  "0915": "Oslo",
  "0950": "Oslo",
  "0951": "Oslo",
  "0952": "Oslo",
  "0953": "Oslo",
  "0954": "Oslo",
  "0955": "Oslo",
  "0956": "Oslo",
  "0957": "Oslo",
  "0958": "Oslo",
  "0959": "Oslo",
  "0960": "Oslo",
  "0962": "Oslo",
  "0963": "Oslo",
  "0964": "Oslo",
  "0968": "Oslo",
  "0969": "Oslo",
  "0970": "Oslo",
  "0971": "Oslo",
  "0972": "Oslo",
  "0973": "Oslo",
  "0975": "Oslo",
  "0976": "Oslo",
  "0977": "Oslo",
  "0978": "Oslo",
  "0979": "Oslo",
  "0980": "Oslo",
  "0981": "Oslo",
  "0982": "Oslo",
  "0983": "Oslo",
  "0984": "Oslo",
  "0985": "Oslo",
  "0986": "Oslo",
  "0987": "Oslo",
  "0988": "Oslo",
  1001: "Oslo",
  1003: "Oslo",
  1005: "Oslo",
  1006: "Oslo",
  1007: "Oslo",
  1008: "Oslo",
  1009: "Oslo",
  1011: "Oslo",
  1051: "Oslo",
  1052: "Oslo",
  1053: "Oslo",
  1054: "Oslo",
  1055: "Oslo",
  1056: "Oslo",
  1061: "Oslo",
  1062: "Oslo",
  1063: "Oslo",
  1064: "Oslo",
  1065: "Oslo",
  1067: "Oslo",
  1068: "Oslo",
  1069: "Oslo",
  1071: "Oslo",
  1081: "Oslo",
  1083: "Oslo",
  1084: "Oslo",
  1086: "Oslo",
  1087: "Oslo",
  1088: "Oslo",
  1089: "Oslo",
  1101: "Oslo",
  1102: "Oslo",
  1109: "Oslo",
  1112: "Oslo",
  1150: "Oslo",
  1151: "Oslo",
  1152: "Oslo",
  1153: "Oslo",
  1154: "Oslo",
  1155: "Oslo",
  1156: "Oslo",
  1157: "Oslo",
  1158: "Oslo",
  1160: "Oslo",
  1161: "Oslo",
  1162: "Oslo",
  1163: "Oslo",
  1164: "Oslo",
  1165: "Oslo",
  1166: "Oslo",
  1167: "Oslo",
  1168: "Oslo",
  1169: "Oslo",
  1170: "Oslo",
  1172: "Oslo",
  1176: "Oslo",
  1177: "Oslo",
  1178: "Oslo",
  1179: "Oslo",
  1181: "Oslo",
  1182: "Oslo",
  1184: "Oslo",
  1185: "Oslo",
  1187: "Oslo",
  1188: "Oslo",
  1189: "Oslo",
  1201: "Oslo",
  1203: "Oslo",
  1204: "Oslo",
  1205: "Oslo",
  1207: "Oslo",
  1214: "Oslo",
  1215: "Oslo",
  1250: "Oslo",
  1251: "Oslo",
  1252: "Oslo",
  1253: "Oslo",
  1254: "Oslo",
  1255: "Oslo",
  1256: "Oslo",
  1257: "Oslo",
  1258: "Oslo",
  1259: "Oslo",
  1262: "Oslo",
  1263: "Oslo",
  1266: "Oslo",
  1270: "Oslo",
  1271: "Oslo",
  1272: "Oslo",
  1273: "Oslo",
  1274: "Oslo",
  1275: "Oslo",
  1278: "Oslo",
  1279: "Oslo",
  1281: "Oslo",
  1283: "Oslo",
  1284: "Oslo",
  1285: "Oslo",
  1286: "Oslo",
  1290: "Oslo",
  1291: "Oslo",
  1294: "Oslo",
  1295: "Oslo",
  1300: "Sandvika",
  1301: "Sandvika",
  1302: "Sandvika",
  1303: "Sandvika",
  1304: "Sandvika",
  1305: "Haslum",
  1306: "Sandvika",
  1307: "Fornebu",
  1308: "Jar",
  1309: "Rud",
  1311: "Høvikodden",
  1312: "Slependen",
  1313: "Vøyenenga",
  1314: "Vøyenenga",
  1316: "Eiksmarka",
  1317: "Bærums Verk",
  1318: "Bekkestua",
  1319: "Bekkestua",
  1321: "Stabekk",
  1322: "Høvik",
  1323: "Høvik",
  1324: "Lysaker",
  1325: "Lysaker",
  1326: "Lysaker",
  1327: "Lysaker",
  1328: "Høvik",
  1329: "Lommedalen",
  1330: "Fornebu",
  1331: "Fornebu",
  1332: "Østerås",
  1333: "Kolsås",
  1334: "Rykkinn",
  1335: "Snarøya",
  1336: "Sandvika",
  1337: "Sandvika",
  1338: "Sandvika",
  1339: "Vøyenenga",
  1340: "Skui",
  1341: "Slependen",
  1342: "Gjettum",
  1344: "Haslum",
  1346: "Gjettum",
  1348: "Rykkinn",
  1349: "Rykkinn",
  1350: "Lommedalen",
  1351: "Rud",
  1352: "Kolsås",
  1353: "Bærums Verk",
  1354: "Bærums Verk",
  1356: "Bekkestua",
  1357: "Bekkestua",
  1358: "Jar",
  1359: "Eiksmarka",
  1360: "Fornebu",
  1361: "Østerås",
  1362: "Hosle",
  1363: "Høvik",
  1364: "Fornebu",
  1365: "Blommenholm",
  1366: "Lysaker",
  1367: "Snarøya",
  1368: "Stabekk",
  1369: "Stabekk",
  1371: "Asker",
  1372: "Asker",
  1373: "Asker",
  1375: "Billingstad",
  1376: "Billingstad",
  1377: "Billingstad",
  1378: "Nesbru",
  1379: "Nesbru",
  1380: "Heggedal",
  1381: "Vettre",
  1383: "Asker",
  1384: "Asker",
  1385: "Asker",
  1386: "Asker",
  1387: "Asker",
  1388: "Borgen",
  1389: "Heggedal",
  1390: "Vollen",
  1391: "Vollen",
  1392: "Vettre",
  1393: "Vollen",
  1394: "Nesbru",
  1395: "Hvalstad",
  1396: "Billingstad",
  1397: "Nesøya",
  1399: "Asker",
  1400: "Ski",
  1401: "Ski",
  1402: "Ski",
  1403: "Langhus",
  1404: "Siggerud",
  1405: "Langhus",
  1406: "Ski",
  1407: "Vinterbro",
  1408: "Kråkstad",
  1409: "Skotbu",
  1410: "Kolbotn",
  1411: "Kolbotn",
  1412: "Sofiemyr",
  1413: "Tårnåsen",
  1414: "Trollåsen",
  1415: "Oppegård",
  1416: "Oppegård",
  1417: "Sofiemyr",
  1418: "Kolbotn",
  1419: "Oppegård",
  1420: "Svartskog",
  1421: "Trollåsen",
  1429: "Vinterbro",
  1430: "Ås",
  1431: "Ås",
  1432: "Ås",
  1433: "Ås",
  1434: "Ås",
  1435: "Ås",
  1440: "Drøbak",
  1441: "Drøbak",
  1442: "Drøbak",
  1443: "Drøbak",
  1444: "Drøbak",
  1445: "Drøbak",
  1446: "Drøbak",
  1447: "Drøbak",
  1448: "Drøbak",
  1449: "Drøbak",
  1450: "Nesoddtangen",
  1451: "Nesoddtangen",
  1452: "Nesoddtangen",
  1453: "Bjørnemyr",
  1454: "Fagerstrand",
  1455: "Nordre Frogn",
  1456: "Nesoddtangen",
  1457: "Fagerstrand",
  1458: "Fjellstrand",
  1459: "Nesodden",
  1465: "Strømmen",
  1466: "Strømmen",
  1467: "Strømmen",
  1468: "Finstadjordet",
  1469: "Rasta",
  1470: "Lørenskog",
  1471: "Lørenskog",
  1472: "Fjellhamar",
  1473: "Lørenskog",
  1474: "Nordbyhagen",
  1475: "Finstadjordet",
  1476: "Rasta",
  1477: "Fjellhamar",
  1478: "Lørenskog",
  1479: "Kurland",
  1480: "Slattum",
  1481: "Hagan",
  1482: "Nittedal",
  1483: "Hagan",
  1484: "Hakadal",
  1485: "Hakadal",
  1486: "Nittedal",
  1487: "Hakadal",
  1488: "Hakadal",
  1501: "Moss",
  1502: "Moss",
  1503: "Moss",
  1504: "Moss",
  1506: "Moss",
  1508: "Moss",
  1509: "Moss",
  1510: "Moss",
  1511: "Moss",
  1512: "Moss",
  1513: "Moss",
  1514: "Moss",
  1515: "Moss",
  1516: "Moss",
  1517: "Moss",
  1518: "Moss",
  1519: "Moss",
  1520: "Moss",
  1521: "Moss",
  1522: "Moss",
  1523: "Moss",
  1524: "Moss",
  1525: "Moss",
  1526: "Moss",
  1528: "Moss",
  1529: "Moss",
  1530: "Moss",
  1531: "Moss",
  1532: "Moss",
  1533: "Moss",
  1534: "Moss",
  1535: "Moss",
  1536: "Moss",
  1537: "Moss",
  1538: "Moss",
  1539: "Moss",
  1540: "Vestby",
  1541: "Vestby",
  1545: "Hvitsten",
  1550: "Hølen",
  1555: "Son",
  1556: "Son",
  1560: "Larkollen",
  1561: "Larkollen",
  1570: "Dilling",
  1580: "Rygge",
  1581: "Rygge",
  1590: "Rygge",
  1591: "Sperrebotn",
  1592: "Våler I Østfold",
  1593: "Svinndal",
  1596: "Moss",
  1597: "Moss",
  1598: "Moss",
  1599: "Moss",
  1601: "Fredrikstad",
  1602: "Fredrikstad",
  1604: "Fredrikstad",
  1605: "Fredrikstad",
  1606: "Fredrikstad",
  1607: "Fredrikstad",
  1608: "Fredrikstad",
  1609: "Fredrikstad",
  1610: "Fredrikstad",
  1612: "Fredrikstad",
  1613: "Fredrikstad",
  1614: "Fredrikstad",
  1615: "Fredrikstad",
  1616: "Fredrikstad",
  1617: "Fredrikstad",
  1618: "Fredrikstad",
  1619: "Fredrikstad",
  1620: "Gressvik",
  1621: "Gressvik",
  1624: "Gressvik",
  1625: "Manstad",
  1626: "Manstad",
  1628: "Engelsviken",
  1629: "Gamle Fredrikstad",
  1630: "Gamle Fredrikstad",
  1632: "Gamle Fredrikstad",
  1633: "Gamle Fredrikstad",
  1634: "Gamle Fredrikstad",
  1636: "Gamle Fredrikstad",
  1637: "Gamle Fredrikstad",
  1638: "Gamle Fredrikstad",
  1639: "Gamle Fredrikstad",
  1640: "Råde",
  1641: "Råde",
  1642: "Saltnes",
  1650: "Sellebakk",
  1651: "Sellebakk",
  1653: "Sellebakk",
  1654: "Sellebakk",
  1655: "Sellebakk",
  1657: "Torp",
  1658: "Torp",
  1659: "Torp",
  1661: "Rolvsøy",
  1662: "Rolvsøy",
  1663: "Rolvsøy",
  1664: "Rolvsøy",
  1665: "Rolvsøy",
  1666: "Rolvsøy",
  1667: "Rolvsøy",
  1670: "Kråkerøy",
  1671: "Kråkerøy",
  1672: "Kråkerøy",
  1673: "Kråkerøy",
  1675: "Kråkerøy",
  1676: "Kråkerøy",
  1678: "Kråkerøy",
  1679: "Kråkerøy",
  1680: "Skjærhalden",
  1682: "Skjærhalden",
  1683: "Vesterøy",
  1684: "Vesterøy",
  1690: "Herføl",
  1692: "Nedgården",
  1701: "Sarpsborg",
  1702: "Sarpsborg",
  1703: "Sarpsborg",
  1704: "Sarpsborg",
  1705: "Sarpsborg",
  1706: "Sarpsborg",
  1707: "Sarpsborg",
  1708: "Sarpsborg",
  1709: "Sarpsborg",
  1710: "Sarpsborg",
  1711: "Sarpsborg",
  1712: "Grålum",
  1713: "Grålum",
  1714: "Grålum",
  1715: "Yven",
  1718: "Greåker",
  1719: "Greåker",
  1720: "Greåker",
  1721: "Sarpsborg",
  1722: "Sarpsborg",
  1723: "Sarpsborg",
  1724: "Sarpsborg",
  1725: "Sarpsborg",
  1726: "Sarpsborg",
  1727: "Sarpsborg",
  1730: "Ise",
  1733: "Hafslundsøy",
  1734: "Hafslundsøy",
  1735: "Varteig",
  1738: "Borgenhaugen",
  1739: "Borgenhaugen",
  1740: "Borgenhaugen",
  1742: "Klavestadhaugen",
  1743: "Klavestadhaugen",
  1745: "Skjeberg",
  1746: "Skjeberg",
  1747: "Skjeberg",
  1751: "Halden",
  1752: "Halden",
  1753: "Halden",
  1754: "Halden",
  1757: "Halden",
  1759: "Halden",
  1760: "Halden",
  1761: "Halden",
  1762: "Halden",
  1763: "Halden",
  1764: "Halden",
  1765: "Halden",
  1766: "Halden",
  1767: "Halden",
  1768: "Halden",
  1769: "Halden",
  1771: "Halden",
  1772: "Halden",
  1776: "Halden",
  1777: "Halden",
  1778: "Halden",
  1779: "Halden",
  1781: "Halden",
  1782: "Halden",
  1783: "Halden",
  1784: "Halden",
  1785: "Halden",
  1786: "Halden",
  1787: "Halden",
  1788: "Halden",
  1789: "Berg I Østfold",
  1790: "Tistedal",
  1791: "Tistedal",
  1792: "Tistedal",
  1793: "Tistedal",
  1794: "Sponvika",
  1796: "Kornsjø",
  1798: "Aremark",
  1799: "Aremark",
  1801: "Askim",
  1802: "Askim",
  1803: "Askim",
  1804: "Spydeberg",
  1805: "Tomter",
  1806: "Skiptvet",
  1807: "Askim",
  1808: "Askim",
  1809: "Askim",
  1811: "Askim",
  1812: "Askim",
  1813: "Askim",
  1814: "Askim",
  1815: "Askim",
  1816: "Skiptvet",
  1820: "Spydeberg",
  1823: "Knapstad",
  1825: "Tomter",
  1827: "Hobøl",
  1830: "Askim",
  1831: "Askim",
  1832: "Askim",
  1833: "Askim",
  1850: "Mysen",
  1851: "Mysen",
  1859: "Slitu",
  1860: "Trøgstad",
  1861: "Trøgstad",
  1866: "Båstad",
  1867: "Båstad",
  1870: "Ørje",
  1871: "Ørje",
  1875: "Otteid",
  1878: "Hærland",
  1880: "Eidsberg",
  1890: "Rakkestad",
  1891: "Rakkestad",
  1892: "Degernes",
  1893: "Degernes",
  1900: "Fetsund",
  1901: "Fetsund",
  1903: "Gan",
  1910: "Enebakkneset",
  1911: "Flateby",
  1912: "Enebakk",
  1914: "Ytre Enebakk",
  1916: "Flateby",
  1917: "Ytre Enebakk",
  1920: "Sørumsand",
  1921: "Sørumsand",
  1923: "Sørum",
  1924: "Sørum",
  1925: "Blaker",
  1926: "Blaker",
  1927: "Rånåsfoss",
  1928: "Auli",
  1929: "Auli",
  1930: "Aurskog",
  1931: "Aurskog",
  1940: "Bjørkelangen",
  1941: "Bjørkelangen",
  1950: "Rømskog",
  1954: "Setskog",
  1960: "Løken",
  1961: "Løken",
  1963: "Fosser",
  1970: "Hemnes",
  1971: "Hemnes",
  2000: "Lillestrøm",
  2001: "Lillestrøm",
  2003: "Lillestrøm",
  2004: "Lillestrøm",
  2005: "Rælingen",
  2006: "Løvenstad",
  2007: "Kjeller",
  2008: "Fjerdingby",
  2009: "Nordby",
  2010: "Strømmen",
  2011: "Strømmen",
  2012: "Lillestrøm",
  2013: "Skjetten",
  2014: "Blystadlia",
  2015: "Leirsund",
  2016: "Frogner",
  2017: "Frogner",
  2018: "Løvenstad",
  2019: "Skedsmokorset",
  2020: "Skedsmokorset",
  2021: "Skedsmokorset",
  2022: "Gjerdrum",
  2023: "Skedsmokorset",
  2024: "Gjerdrum",
  2025: "Fjerdingby",
  2026: "Skjetten",
  2027: "Kjeller",
  2028: "Lillestrøm",
  2030: "Nannestad",
  2031: "Nannestad",
  2032: "Maura",
  2033: "Åsgreina",
  2034: "Holter",
  2035: "Holter",
  2036: "Maura",
  2040: "Kløfta",
  2041: "Kløfta",
  2050: "Jessheim",
  2051: "Jessheim",
  2052: "Jessheim",
  2053: "Jessheim",
  2054: "Mogreina",
  2055: "Nordkisa",
  2056: "Algarheim",
  2057: "Jessheim",
  2058: "Sessvollmoen",
  2060: "Gardermoen",
  2061: "Gardermoen",
  2062: "Jessheim",
  2063: "Jessheim",
  2066: "Jessheim",
  2067: "Jessheim",
  2068: "Jessheim",
  2069: "Jessheim",
  2070: "Råholt",
  2071: "Råholt",
  2072: "Dal",
  2073: "Bøn",
  2074: "Eidsvoll Verk",
  2076: "Dal",
  2080: "Eidsvoll",
  2081: "Eidsvoll",
  2090: "Hurdal",
  2091: "Hurdal",
  2092: "Minnesund",
  2093: "Feiring",
  2094: "Minnesund",
  2100: "Skarnes",
  2101: "Skarnes",
  2110: "Slåstad",
  2114: "Disenå",
  2116: "Sander",
  2120: "Sagstua",
  2123: "Bruvoll",
  2130: "Knapper",
  2133: "Gardvik",
  2134: "Austvatn",
  2150: "Årnes",
  2151: "Årnes",
  2160: "Vormsund",
  2161: "Vormsund",
  2162: "Brårud",
  2164: "Skogbygda",
  2165: "Hvam",
  2166: "Oppaker",
  2170: "Fenstad",
  2201: "Kongsvinger",
  2202: "Kongsvinger",
  2203: "Kongsvinger",
  2204: "Kongsvinger",
  2205: "Kongsvinger",
  2206: "Kongsvinger",
  2207: "Kongsvinger",
  2208: "Kongsvinger",
  2209: "Kongsvinger",
  2210: "Granli",
  2211: "Kongsvinger",
  2212: "Kongsvinger",
  2213: "Kongsvinger",
  2214: "Kongsvinger",
  2216: "Roverud",
  2217: "Hokkåsen",
  2218: "Lundersæter",
  2219: "Brandval",
  2220: "Åbogen",
  2223: "Galterud",
  2224: "Austmarka",
  2225: "Kongsvinger",
  2226: "Kongsvinger",
  2230: "Skotterud",
  2231: "Skotterud",
  2232: "Tobøl",
  2233: "Vestmarka",
  2235: "Matrand",
  2240: "Magnor",
  2241: "Magnor",
  2256: "Grue Finnskog",
  2260: "Kirkenær",
  2261: "Kirkenær",
  2264: "Grinder",
  2265: "Namnå",
  2266: "Arneberg",
  2270: "Flisa",
  2271: "Flisa",
  2280: "Gjesåsen",
  2283: "Åsnes Finnskog",
  2301: "Hamar",
  2302: "Hamar",
  2303: "Hamar",
  2304: "Hamar",
  2305: "Hamar",
  2306: "Hamar",
  2307: "Hamar",
  2308: "Hamar",
  2309: "Hamar",
  2312: "Ottestad",
  2313: "Ottestad",
  2314: "Ottestad",
  2315: "Hamar",
  2316: "Hamar",
  2317: "Hamar",
  2318: "Hamar",
  2319: "Hamar",
  2320: "Furnes",
  2321: "Hamar",
  2322: "Ridabu",
  2323: "Ingeberg",
  2324: "Vang På Hedmarken",
  2325: "Hamar",
  2326: "Hamar",
  2330: "Vallset",
  2332: "Åsvang",
  2333: "Romedal",
  2334: "Romedal",
  2335: "Stange",
  2336: "Stange",
  2337: "Tangen",
  2338: "Espa",
  2340: "Løten",
  2341: "Løten",
  2344: "Ilseng",
  2345: "Ådalsbruk",
  2350: "Nes På Hedmarken",
  2353: "Stavsjø",
  2355: "Gaupen",
  2360: "Rudshøgda",
  2364: "Næroset",
  2365: "Åsmarka",
  2372: "Brøttum",
  2380: "Brumunddal",
  2381: "Brumunddal",
  2382: "Brumunddal",
  2383: "Brumunddal",
  2384: "Brumunddal",
  2385: "Brumunddal",
  2386: "Brumunddal",
  2387: "Brumunddal",
  2388: "Brumunddal",
  2389: "Brumunddal",
  2390: "Moelv",
  2391: "Moelv",
  2401: "Elverum",
  2402: "Elverum",
  2403: "Elverum",
  2404: "Elverum",
  2405: "Elverum",
  2406: "Elverum",
  2407: "Elverum",
  2408: "Elverum",
  2409: "Elverum",
  2410: "Hernes",
  2411: "Elverum",
  2412: "Sørskogbygda",
  2415: "Heradsbygd",
  2416: "Jømna",
  2418: "Elverum",
  2420: "Trysil",
  2421: "Trysil",
  2422: "Nybergsund",
  2423: "Østby",
  2425: "Ljørdalen",
  2427: "Plassen",
  2428: "Søre Osen",
  2429: "Tørberget",
  2430: "Jordet",
  2432: "Slettås",
  2435: "Braskereidfoss",
  2436: "Våler I Solør",
  2437: "Haslemoen",
  2438: "Gravberget",
  2439: "Våler I Solør",
  2440: "Engerdal",
  2443: "Drevsjø",
  2446: "Elgå",
  2448: "Sømådalen",
  2450: "Rena",
  2451: "Rena",
  2460: "Osen",
  2476: "Atna",
  2477: "Sollia",
  2478: "Hanestad",
  2480: "Koppang",
  2481: "Koppang",
  2485: "Rendalen",
  2486: "Rendalen",
  2487: "Rendalen",
  2488: "Rendalen",
  2500: "Tynset",
  2501: "Tynset",
  2510: "Tylldalen",
  2512: "Kvikne",
  2540: "Tolga",
  2542: "Vingelen",
  2544: "Øversjødalen",
  2550: "Os I Østerdalen",
  2552: "Dalsbygda",
  2555: "Tufsingdalen",
  2560: "Alvdal",
  2561: "Alvdal",
  2580: "Folldal",
  2581: "Folldal",
  2582: "Grimsbu",
  2584: "Dalholen",
  2601: "Lillehammer",
  2602: "Lillehammer",
  2603: "Lillehammer",
  2604: "Lillehammer",
  2605: "Lillehammer",
  2606: "Lillehammer",
  2607: "Vingrom",
  2608: "Lillehammer",
  2609: "Lillehammer",
  2610: "Mesnali",
  2611: "Lillehammer",
  2612: "Sjusjøen",
  2613: "Lillehammer",
  2614: "Lillehammer",
  2615: "Lillehammer",
  2616: "Lismarka",
  2617: "Lillehammer",
  2618: "Lillehammer",
  2619: "Lillehammer",
  2624: "Lillehammer",
  2625: "Fåberg",
  2626: "Lillehammer",
  2629: "Lillehammer",
  2630: "Ringebu",
  2631: "Ringebu",
  2632: "Venabygd",
  2633: "Fåvang",
  2634: "Fåvang",
  2635: "Tretten",
  2636: "Øyer",
  2637: "Øyer",
  2639: "Vinstra",
  2640: "Vinstra",
  2642: "Kvam",
  2643: "Skåbu",
  2645: "Sør-Fron",
  2646: "Gålå",
  2647: "Sør-Fron",
  2648: "Sør-Fron",
  2649: "Østre Gausdal",
  2651: "Østre Gausdal",
  2652: "Svingvoll",
  2653: "Vestre Gausdal",
  2656: "Follebu",
  2657: "Svatsum",
  2658: "Espedalen",
  2659: "Dombås",
  2660: "Dombås",
  2661: "Hjerkinn",
  2662: "Dovre",
  2663: "Dovreskogen",
  2664: "Dovre",
  2665: "Lesja",
  2666: "Lora",
  2667: "Lesjaverk",
  2668: "Lesjaskog",
  2669: "Bjorli",
  2670: "Otta",
  2672: "Sel",
  2673: "Høvringen",
  2674: "Mysusæter",
  2675: "Otta",
  2676: "Heidal",
  2677: "Nedre Heidal",
  2680: "Vågå",
  2682: "Lalm",
  2683: "Tessanden",
  2684: "Vågå",
  2685: "Garmo",
  2686: "Lom",
  2687: "Bøverdalen",
  2688: "Lom",
  2690: "Skjåk",
  2693: "Nordberg",
  2694: "Skjåk",
  2695: "Grotli",
  2711: "Gran",
  2712: "Brandbu",
  2713: "Roa",
  2714: "Jaren",
  2715: "Lunner",
  2716: "Harestua",
  2717: "Grua",
  2718: "Brandbu",
  2720: "Grindvoll",
  2730: "Lunner",
  2740: "Roa",
  2742: "Grua",
  2743: "Harestua",
  2750: "Gran",
  2760: "Brandbu",
  2770: "Jaren",
  2801: "Gjøvik",
  2802: "Gjøvik",
  2803: "Gjøvik",
  2804: "Gjøvik",
  2805: "Gjøvik",
  2806: "Gjøvik",
  2807: "Hunndalen",
  2808: "Gjøvik",
  2809: "Gjøvik",
  2810: "Gjøvik",
  2811: "Hunndalen",
  2815: "Gjøvik",
  2816: "Gjøvik",
  2817: "Gjøvik",
  2818: "Gjøvik",
  2819: "Gjøvik",
  2820: "Nordre Toten",
  2821: "Gjøvik",
  2822: "Bybrua",
  2825: "Gjøvik",
  2827: "Hunndalen",
  2830: "Raufoss",
  2831: "Raufoss",
  2832: "Biri",
  2833: "Raufoss",
  2834: "Raufoss",
  2835: "Raufoss",
  2836: "Biri",
  2837: "Biristrand",
  2838: "Snertingdal",
  2839: "Øvre Snertingdal",
  2840: "Reinsvoll",
  2843: "Eina",
  2846: "Bøverbru",
  2847: "Kolbu",
  2848: "Skreia",
  2849: "Kapp",
  2850: "Lena",
  2851: "Lena",
  2853: "Reinsvoll",
  2854: "Eina",
  2857: "Skreia",
  2858: "Kapp",
  2860: "Hov",
  2861: "Landåsbygda",
  2862: "Fluberg",
  2864: "Fall",
  2866: "Enger",
  2867: "Hov",
  2870: "Dokka",
  2879: "Odnes",
  2880: "Nord-Torpa",
  2881: "Aust-Torpa",
  2882: "Dokka",
  2890: "Etnedal",
  2893: "Etnedal",
  2900: "Fagernes",
  2901: "Fagernes",
  2907: "Leira I Valdres",
  2909: "Aurdal",
  2910: "Aurdal",
  2917: "Skrautvål",
  2918: "Ulnes",
  2920: "Leira I Valdres",
  2923: "Tisleidalen",
  2929: "Bagn",
  2930: "Bagn",
  2933: "Reinli",
  2936: "Begnadalen",
  2937: "Begna",
  2939: "Heggenes",
  2940: "Heggenes",
  2943: "Rogne",
  2950: "Skammestein",
  2952: "Beito",
  2953: "Beitostølen",
  2954: "Beitostølen",
  2959: "Røn",
  2960: "Røn",
  2965: "Slidre",
  2966: "Slidre",
  2967: "Lomen",
  2972: "Ryfoss",
  2973: "Ryfoss",
  2974: "Vang I Valdres",
  2975: "Vang I Valdres",
  2977: "Øye",
  2985: "Tyinkrysset",
  3001: "Drammen",
  3002: "Drammen",
  3003: "Drammen",
  3004: "Drammen",
  3005: "Drammen",
  3006: "Drammen",
  3007: "Drammen",
  3008: "Drammen",
  3009: "Drammen",
  3010: "Drammen",
  3011: "Drammen",
  3012: "Drammen",
  3013: "Drammen",
  3014: "Drammen",
  3015: "Drammen",
  3016: "Drammen",
  3017: "Drammen",
  3018: "Drammen",
  3019: "Drammen",
  3021: "Drammen",
  3022: "Drammen",
  3023: "Drammen",
  3024: "Drammen",
  3025: "Drammen",
  3026: "Drammen",
  3027: "Drammen",
  3028: "Drammen",
  3029: "Drammen",
  3030: "Drammen",
  3031: "Drammen",
  3032: "Drammen",
  3033: "Drammen",
  3034: "Drammen",
  3035: "Drammen",
  3036: "Drammen",
  3037: "Drammen",
  3038: "Drammen",
  3039: "Drammen",
  3040: "Drammen",
  3041: "Drammen",
  3042: "Drammen",
  3043: "Drammen",
  3044: "Drammen",
  3045: "Drammen",
  3046: "Drammen",
  3047: "Drammen",
  3048: "Drammen",
  3050: "Mjøndalen",
  3051: "Mjøndalen",
  3053: "Steinberg",
  3054: "Krokstadelva",
  3055: "Krokstadelva",
  3056: "Solbergelva",
  3057: "Solbergelva",
  3058: "Solbergmoen",
  3060: "Svelvik",
  3061: "Svelvik",
  3070: "Sande I Vestfold",
  3071: "Sande I Vestfold",
  3072: "Sande I Vestfold",
  3073: "Sande I Vestfold",
  3074: "Sande I Vestfold",
  3075: "Berger",
  3076: "Sande I Vestfold",
  3077: "Sande I Vestfold",
  3080: "Holmestrand",
  3081: "Holmestrand",
  3082: "Holmestrand",
  3083: "Holmestrand",
  3084: "Holmestrand",
  3085: "Holmestrand",
  3086: "Holmestrand",
  3087: "Holmestrand",
  3088: "Holmestrand",
  3089: "Holmestrand",
  3090: "Hof",
  3091: "Hof",
  3092: "Sundbyfoss",
  3095: "Eidsfoss",
  3101: "Tønsberg",
  3103: "Tønsberg",
  3104: "Tønsberg",
  3105: "Tønsberg",
  3106: "Nøtterøy",
  3107: "Sem",
  3108: "Vear",
  3109: "Tønsberg",
  3110: "Tønsberg",
  3111: "Tønsberg",
  3112: "Tønsberg",
  3113: "Tønsberg",
  3114: "Tønsberg",
  3115: "Tønsberg",
  3116: "Tønsberg",
  3117: "Tønsberg",
  3118: "Tønsberg",
  3119: "Tønsberg",
  3120: "Nøtterøy",
  3121: "Nøtterøy",
  3122: "Tønsberg",
  3123: "Tønsberg",
  3124: "Tønsberg",
  3125: "Tønsberg",
  3126: "Tønsberg",
  3127: "Tønsberg",
  3128: "Nøtterøy",
  3131: "Husøysund",
  3132: "Husøysund",
  3133: "Duken",
  3135: "Torød",
  3137: "Torød",
  3138: "Skallestad",
  3139: "Skallestad",
  3140: "Nøtterøy",
  3141: "Kjøpmannskjær",
  3142: "Vestskogen",
  3143: "Kjøpmannskjær",
  3144: "Veierland",
  3145: "Tjøme",
  3148: "Hvasser",
  3150: "Tolvsrød",
  3151: "Tolvsrød",
  3152: "Tolvsrød",
  3153: "Tolvsrød",
  3154: "Tolvsrød",
  3157: "Barkåker",
  3158: "Andebu",
  3159: "Melsomvik",
  3160: "Stokke",
  3161: "Stokke",
  3162: "Andebu",
  3163: "Nøtterøy",
  3164: "Revetal",
  3165: "Tjøme",
  3166: "Tolvsrød",
  3167: "Åsgårdstrand",
  3168: "Melsomvik",
  3169: "Stokke",
  3170: "Sem",
  3171: "Sem",
  3172: "Vear",
  3173: "Vear",
  3174: "Revetal",
  3175: "Ramnes",
  3176: "Undrumsdal",
  3177: "Våle",
  3178: "Våle",
  3179: "Åsgårdstrand",
  3180: "Nykirke",
  3181: "Horten",
  3182: "Horten",
  3183: "Horten",
  3184: "Borre",
  3185: "Skoppum",
  3186: "Horten",
  3187: "Horten",
  3188: "Horten",
  3189: "Horten",
  3191: "Horten",
  3192: "Horten",
  3193: "Horten",
  3194: "Horten",
  3195: "Skoppum",
  3196: "Horten",
  3199: "Borre",
  3201: "Sandefjord",
  3202: "Sandefjord",
  3203: "Sandefjord",
  3204: "Sandefjord",
  3205: "Sandefjord",
  3206: "Sandefjord",
  3207: "Sandefjord",
  3208: "Sandefjord",
  3209: "Sandefjord",
  3210: "Sandefjord",
  3211: "Sandefjord",
  3212: "Sandefjord",
  3213: "Sandefjord",
  3214: "Sandefjord",
  3215: "Sandefjord",
  3216: "Sandefjord",
  3217: "Sandefjord",
  3218: "Sandefjord",
  3219: "Sandefjord",
  3220: "Sandefjord",
  3221: "Sandefjord",
  3222: "Sandefjord",
  3223: "Sandefjord",
  3224: "Sandefjord",
  3225: "Sandefjord",
  3226: "Sandefjord",
  3227: "Sandefjord",
  3228: "Sandefjord",
  3229: "Sandefjord",
  3230: "Sandefjord",
  3231: "Sandefjord",
  3232: "Sandefjord",
  3233: "Sandefjord",
  3234: "Sandefjord",
  3235: "Sandefjord",
  3236: "Sandefjord",
  3237: "Sandefjord",
  3238: "Sandefjord",
  3239: "Sandefjord",
  3240: "Sandefjord",
  3241: "Sandefjord",
  3242: "Sandefjord",
  3243: "Kodal",
  3244: "Sandefjord",
  3245: "Kodal",
  3246: "Sandefjord",
  3249: "Sandefjord",
  3251: "Larvik",
  3252: "Larvik",
  3253: "Larvik",
  3254: "Larvik",
  3255: "Larvik",
  3256: "Larvik",
  3257: "Larvik",
  3258: "Larvik",
  3259: "Larvik",
  3260: "Larvik",
  3261: "Larvik",
  3262: "Larvik",
  3263: "Larvik",
  3264: "Larvik",
  3265: "Larvik",
  3267: "Larvik",
  3268: "Larvik",
  3269: "Larvik",
  3270: "Larvik",
  3271: "Larvik",
  3274: "Larvik",
  3275: "Svarstad",
  3276: "Svarstad",
  3277: "Steinsholt",
  3280: "Tjodalyng",
  3281: "Tjodalyng",
  3282: "Kvelde",
  3284: "Kvelde",
  3285: "Larvik",
  3290: "Stavern",
  3291: "Stavern",
  3292: "Stavern",
  3294: "Stavern",
  3295: "Helgeroa",
  3296: "Nevlunghavn",
  3297: "Helgeroa",
  3300: "Hokksund",
  3301: "Hokksund",
  3302: "Hokksund",
  3303: "Hokksund",
  3320: "Vestfossen",
  3321: "Vestfossen",
  3322: "Fiskum",
  3330: "Skotselv",
  3331: "Skotselv",
  3340: "Åmot",
  3341: "Åmot",
  3342: "Åmot",
  3350: "Prestfoss",
  3351: "Prestfoss",
  3355: "Solumsmoen",
  3358: "Nedre Eggedal",
  3359: "Eggedal",
  3360: "Geithus",
  3361: "Geithus",
  3370: "Vikersund",
  3371: "Vikersund",
  3401: "Lier",
  3402: "Lier",
  3403: "Lier",
  3404: "Lier",
  3405: "Lier",
  3406: "Tranby",
  3407: "Tranby",
  3408: "Tranby",
  3409: "Tranby",
  3410: "Sylling",
  3411: "Sylling",
  3412: "Lierstranda",
  3413: "Lier",
  3414: "Lierstranda",
  3420: "Lierskogen",
  3421: "Lierskogen",
  3425: "Reistad",
  3426: "Gullaug",
  3427: "Gullaug",
  3428: "Gullaug",
  3430: "Spikkestad",
  3431: "Spikkestad",
  3440: "Røyken",
  3441: "Røyken",
  3442: "Hyggen",
  3470: "Slemmestad",
  3471: "Slemmestad",
  3472: "Bødalen",
  3474: "Åros",
  3475: "Sætre",
  3476: "Sætre",
  3477: "Båtstø",
  3478: "Nærsnes",
  3479: "Nærsnes",
  3480: "Filtvet",
  3481: "Tofte",
  3482: "Tofte",
  3483: "Kana",
  3484: "Holmsbu",
  3490: "Klokkarstua",
  3501: "Hønefoss",
  3502: "Hønefoss",
  3503: "Hønefoss",
  3504: "Hønefoss",
  3507: "Hønefoss",
  3510: "Hønefoss",
  3511: "Hønefoss",
  3512: "Hønefoss",
  3513: "Hønefoss",
  3514: "Hønefoss",
  3515: "Hønefoss",
  3516: "Hønefoss",
  3517: "Hønefoss",
  3518: "Hønefoss",
  3519: "Hønefoss",
  3520: "Jevnaker",
  3521: "Jevnaker",
  3522: "Bjoneroa",
  3523: "Nes I Ådal",
  3524: "Nes I Ådal",
  3525: "Hallingby",
  3526: "Hallingby",
  3528: "Hedalen",
  3529: "Røyse",
  3530: "Røyse",
  3531: "Krokkleiva",
  3533: "Tyristrand",
  3534: "Sokna",
  3535: "Krøderen",
  3536: "Noresund",
  3537: "Krøderen",
  3538: "Sollihøgda",
  3539: "Flå",
  3540: "Nesbyen",
  3541: "Nesbyen",
  3544: "Tunhovd",
  3550: "Gol",
  3551: "Gol",
  3560: "Hemsedal",
  3561: "Hemsedal",
  3570: "Ål",
  3571: "Ål",
  3575: "Hol",
  3576: "Hol",
  3577: "Hovet",
  3579: "Torpo",
  3580: "Geilo",
  3581: "Geilo",
  3588: "Dagali",
  3593: "Ustaoset",
  3595: "Haugastøl",
  3601: "Kongsberg",
  3602: "Kongsberg",
  3603: "Kongsberg",
  3604: "Kongsberg",
  3605: "Kongsberg",
  3606: "Kongsberg",
  3608: "Heistadmoen",
  3609: "Kongsberg",
  3610: "Kongsberg",
  3611: "Kongsberg",
  3612: "Kongsberg",
  3613: "Kongsberg",
  3614: "Kongsberg",
  3615: "Kongsberg",
  3616: "Kongsberg",
  3617: "Kongsberg",
  3618: "Skollenborg",
  3619: "Skollenborg",
  3620: "Flesberg",
  3621: "Lampeland",
  3622: "Svene",
  3623: "Lampeland",
  3624: "Lyngdal I Numedal",
  3626: "Rollag",
  3627: "Veggli",
  3628: "Veggli",
  3629: "Nore",
  3630: "Rødberg",
  3631: "Rødberg",
  3632: "Uvdal",
  3646: "Hvittingfoss",
  3647: "Hvittingfoss",
  3648: "Passebekk",
  3650: "Tinn Austbygd",
  3652: "Hovin I Telemark",
  3656: "Atrå",
  3658: "Miland",
  3660: "Rjukan",
  3661: "Rjukan",
  3665: "Sauland",
  3666: "Tinn Austbygd",
  3671: "Notodden",
  3672: "Notodden",
  3673: "Notodden",
  3674: "Notodden",
  3675: "Notodden",
  3676: "Notodden",
  3677: "Notodden",
  3678: "Notodden",
  3679: "Notodden",
  3680: "Notodden",
  3681: "Notodden",
  3683: "Notodden",
  3684: "Notodden",
  3690: "Hjartdal",
  3691: "Gransherad",
  3692: "Sauland",
  3697: "Tuddal",
  3701: "Skien",
  3702: "Skien",
  3703: "Skien",
  3704: "Skien",
  3705: "Skien",
  3707: "Skien",
  3710: "Skien",
  3711: "Skien",
  3712: "Skien",
  3713: "Skien",
  3714: "Skien",
  3715: "Skien",
  3716: "Skien",
  3717: "Skien",
  3718: "Skien",
  3719: "Skien",
  3720: "Skien",
  3721: "Skien",
  3722: "Skien",
  3723: "Skien",
  3724: "Skien",
  3725: "Skien",
  3726: "Skien",
  3727: "Skien",
  3728: "Skien",
  3729: "Skien",
  3730: "Skien",
  3731: "Skien",
  3732: "Skien",
  3733: "Skien",
  3734: "Skien",
  3735: "Skien",
  3736: "Skien",
  3737: "Skien",
  3738: "Skien",
  3739: "Skien",
  3740: "Skien",
  3741: "Skien",
  3742: "Skien",
  3743: "Skien",
  3744: "Skien",
  3746: "Skien",
  3747: "Skien",
  3748: "Siljan",
  3749: "Siljan",
  3750: "Drangedal",
  3753: "Tørdal",
  3760: "Neslandsvatn",
  3766: "Sannidal",
  3770: "Kragerø",
  3772: "Kragerø",
  3780: "Skåtøy",
  3781: "Jomfruland",
  3783: "Kragerø Skjærgård",
  3785: "Skien",
  3787: "Skien",
  3788: "Stabbestad",
  3789: "Kragerø",
  3790: "Helle",
  3791: "Kragerø",
  3792: "Skien",
  3793: "Sannidal",
  3794: "Helle",
  3795: "Drangedal",
  3796: "Skien",
  3798: "Skien",
  3799: "Skien",
  3800: "Bø I Telemark",
  3801: "Bø I Telemark",
  3802: "Bø I Telemark",
  3803: "Bø I Telemark",
  3804: "Bø I Telemark",
  3805: "Bø I Telemark",
  3810: "Gvarv",
  3811: "Hørte",
  3812: "Akkerhaugen",
  3820: "Nordagutu",
  3825: "Lunde",
  3830: "Ulefoss",
  3831: "Ulefoss",
  3832: "Lunde",
  3833: "Bø I Telemark",
  3834: "Gvarv",
  3835: "Seljord",
  3836: "Kviteseid",
  3840: "Seljord",
  3841: "Flatdal",
  3844: "Åmotsdal",
  3848: "Morgedal",
  3849: "Vråliosen",
  3850: "Kviteseid",
  3852: "Vrådal",
  3853: "Vrådal",
  3854: "Nissedal",
  3855: "Treungen",
  3864: "Rauland",
  3870: "Fyresdal",
  3880: "Dalen",
  3882: "Åmdals Verk",
  3883: "Treungen",
  3884: "Rauland",
  3885: "Fyresdal",
  3886: "Dalen",
  3887: "Vinje",
  3888: "Edland",
  3890: "Vinje",
  3891: "Høydalsmo",
  3893: "Vinjesvingen",
  3895: "Edland",
  3901: "Porsgrunn",
  3902: "Porsgrunn",
  3903: "Porsgrunn",
  3904: "Porsgrunn",
  3905: "Porsgrunn",
  3906: "Porsgrunn",
  3910: "Porsgrunn",
  3911: "Porsgrunn",
  3912: "Porsgrunn",
  3913: "Porsgrunn",
  3914: "Porsgrunn",
  3915: "Porsgrunn",
  3916: "Porsgrunn",
  3917: "Porsgrunn",
  3918: "Porsgrunn",
  3919: "Porsgrunn",
  3920: "Porsgrunn",
  3921: "Porsgrunn",
  3922: "Porsgrunn",
  3924: "Porsgrunn",
  3925: "Porsgrunn",
  3928: "Porsgrunn",
  3929: "Porsgrunn",
  3930: "Porsgrunn",
  3931: "Porsgrunn",
  3933: "Porsgrunn",
  3936: "Porsgrunn",
  3937: "Porsgrunn",
  3939: "Porsgrunn",
  3940: "Porsgrunn",
  3941: "Porsgrunn",
  3942: "Porsgrunn",
  3943: "Porsgrunn",
  3944: "Porsgrunn",
  3946: "Porsgrunn",
  3947: "Langangen",
  3948: "Porsgrunn",
  3949: "Porsgrunn",
  3950: "Brevik",
  3960: "Stathelle",
  3961: "Stathelle",
  3962: "Stathelle",
  3965: "Herre",
  3966: "Stathelle",
  3967: "Stathelle",
  3970: "Langesund",
  3991: "Brevik",
  3993: "Langesund",
  3994: "Langesund",
  3995: "Stathelle",
  3996: "Porsgrunn",
  3997: "Porsgrunn",
  3998: "Porsgrunn",
  3999: "Herre",
  4001: "Stavanger",
  4002: "Stavanger",
  4003: "Stavanger",
  4004: "Stavanger",
  4005: "Stavanger",
  4006: "Stavanger",
  4007: "Stavanger",
  4008: "Stavanger",
  4009: "Stavanger",
  4010: "Stavanger",
  4011: "Stavanger",
  4012: "Stavanger",
  4013: "Stavanger",
  4014: "Stavanger",
  4015: "Stavanger",
  4016: "Stavanger",
  4017: "Stavanger",
  4018: "Stavanger",
  4019: "Stavanger",
  4020: "Stavanger",
  4021: "Stavanger",
  4022: "Stavanger",
  4023: "Stavanger",
  4024: "Stavanger",
  4025: "Stavanger",
  4026: "Stavanger",
  4027: "Stavanger",
  4028: "Stavanger",
  4029: "Stavanger",
  4031: "Stavanger",
  4032: "Stavanger",
  4033: "Stavanger",
  4034: "Stavanger",
  4035: "Stavanger",
  4036: "Stavanger",
  4041: "Hafrsfjord",
  4042: "Hafrsfjord",
  4043: "Hafrsfjord",
  4044: "Hafrsfjord",
  4045: "Hafrsfjord",
  4046: "Hafrsfjord",
  4047: "Hafrsfjord",
  4048: "Hafrsfjord",
  4049: "Hafrsfjord",
  4050: "Sola",
  4051: "Sola",
  4052: "Røyneberg",
  4053: "Ræge",
  4054: "Tjelta",
  4055: "Sola",
  4056: "Tananger",
  4057: "Tananger",
  4058: "Tananger",
  4059: "Røyneberg",
  4064: "Stavanger",
  4065: "Stavanger",
  4066: "Stavanger",
  4067: "Stavanger",
  4068: "Stavanger",
  4069: "Stavanger",
  4070: "Randaberg",
  4071: "Randaberg",
  4072: "Randaberg",
  4073: "Randaberg",
  4076: "Vassøy",
  4077: "Hundvåg",
  4078: "Stavanger",
  4079: "Stavanger",
  4081: "Stavanger",
  4082: "Stavanger",
  4083: "Hundvåg",
  4084: "Stavanger",
  4085: "Hundvåg",
  4086: "Hundvåg",
  4087: "Stavanger",
  4088: "Stavanger",
  4089: "Hafrsfjord",
  4090: "Hafrsfjord",
  4091: "Hafrsfjord",
  4092: "Stavanger",
  4093: "Stavanger",
  4094: "Stavanger",
  4095: "Stavanger",
  4096: "Randaberg",
  4097: "Sola",
  4098: "Tananger",
  4099: "Stavanger",
  4100: "Jørpeland",
  4102: "Idse",
  4110: "Forsand",
  4119: "Forsand",
  4120: "Tau",
  4123: "Sør-Hidle",
  4124: "Tau",
  4126: "Jørpeland",
  4127: "Lysebotn",
  4128: "Fløyrli",
  4129: "Songesand",
  4130: "Hjelmeland",
  4134: "Jøsenfjorden",
  4137: "Årdal I Ryfylke",
  4139: "Fister",
  4146: "Skiftun",
  4148: "Hjelmeland",
  4150: "Rennesøy",
  4152: "Vestre Åmøy",
  4153: "Brimse",
  4154: "Austre Åmøy",
  4156: "Mosterøy",
  4158: "Bru",
  4159: "Rennesøy",
  4160: "Finnøy",
  4161: "Finnøy",
  4163: "Talgje",
  4164: "Fogn",
  4167: "Helgøy I Ryfylke",
  4168: "Byre",
  4169: "Sørbokn",
  4170: "Sjernarøy",
  4173: "Nord-Hidle",
  4174: "Sjernarøy",
  4180: "Kvitsøy",
  4181: "Kvitsøy",
  4182: "Skartveit",
  4187: "Ombo",
  4198: "Foldøy",
  4200: "Sauda",
  4201: "Sauda",
  4208: "Saudasjøen",
  4209: "Vanvik",
  4230: "Sand",
  4233: "Erfjord",
  4234: "Jelsa",
  4235: "Hebnes",
  4237: "Suldalsosen",
  4239: "Sand",
  4240: "Suldalsosen",
  4244: "Nesflaten",
  4250: "Kopervik",
  4260: "Torvastad",
  4262: "Avaldsnes",
  4264: "Kvalavåg",
  4265: "Håvik",
  4270: "Åkrehamn",
  4272: "Sandve",
  4274: "Stol",
  4275: "Sævelandsvik",
  4276: "Veavågen",
  4280: "Skudeneshavn",
  4291: "Kopervik",
  4294: "Kopervik",
  4295: "Veavågen",
  4296: "Åkrehamn",
  4297: "Skudeneshavn",
  4298: "Torvastad",
  4299: "Avaldsnes",
  4301: "Sandnes",
  4302: "Sandnes",
  4303: "Sandnes",
  4304: "Sandnes",
  4305: "Sandnes",
  4306: "Sandnes",
  4307: "Sandnes",
  4308: "Sandnes",
  4309: "Sandnes",
  4310: "Hommersåk",
  4311: "Hommersåk",
  4312: "Sandnes",
  4313: "Sandnes",
  4314: "Sandnes",
  4315: "Sandnes",
  4316: "Sandnes",
  4317: "Sandnes",
  4318: "Sandnes",
  4319: "Sandnes",
  4320: "Sandnes",
  4321: "Sandnes",
  4322: "Sandnes",
  4323: "Sandnes",
  4324: "Sandnes",
  4325: "Sandnes",
  4326: "Sandnes",
  4327: "Sandnes",
  4328: "Sandnes",
  4329: "Sandnes",
  4330: "Ålgård",
  4332: "Figgjo",
  4333: "Oltedal",
  4335: "Dirdal",
  4336: "Sandnes",
  4337: "Sandnes",
  4338: "Sandnes",
  4339: "Ålgård",
  4340: "Bryne",
  4341: "Bryne",
  4342: "Undheim",
  4343: "Orre",
  4344: "Bryne",
  4345: "Bryne",
  4346: "Bryne",
  4347: "Lye",
  4348: "Lye",
  4349: "Bryne",
  4352: "Kleppe",
  4353: "Klepp Stasjon",
  4354: "Voll",
  4355: "Kvernaland",
  4356: "Kvernaland",
  4357: "Klepp Stasjon",
  4358: "Kleppe",
  4360: "Varhaug",
  4362: "Vigrestad",
  4363: "Brusand",
  4364: "Sirevåg",
  4365: "Nærbø",
  4367: "Nærbø",
  4368: "Varhaug",
  4369: "Vigrestad",
  4370: "Egersund",
  4371: "Egersund",
  4372: "Egersund",
  4373: "Egersund",
  4374: "Egersund",
  4375: "Hellvik",
  4376: "Helleland",
  4378: "Egersund",
  4379: "Egersund",
  4380: "Hauge I Dalane",
  4381: "Hauge I Dalane",
  4387: "Bjerkreim",
  4389: "Vikeså",
  4391: "Sandnes",
  4392: "Sandnes",
  4393: "Sandnes",
  4394: "Sandnes",
  4395: "Hommersåk",
  4396: "Sandnes",
  4397: "Sandnes",
  4398: "Sandnes",
  4400: "Flekkefjord",
  4401: "Flekkefjord",
  4402: "Flekkefjord",
  4403: "Flekkefjord",
  4420: "Åna-Sira",
  4432: "Hidrasund",
  4434: "Andabeløy",
  4436: "Gyland",
  4438: "Sira",
  4440: "Tonstad",
  4441: "Tonstad",
  4443: "Tjørhom",
  4460: "Moi",
  4462: "Hovsherad",
  4463: "Ualand",
  4465: "Moi",
  4473: "Kvinlog",
  4480: "Kvinesdal",
  4484: "Øyestranda",
  4485: "Feda",
  4490: "Kvinesdal",
  4491: "Kvinesdal",
  4492: "Kvinesdal",
  4501: "Mandal",
  4502: "Mandal",
  4503: "Mandal",
  4504: "Mandal",
  4505: "Mandal",
  4506: "Mandal",
  4507: "Mandal",
  4508: "Mandal",
  4509: "Mandal",
  4513: "Mandal",
  4514: "Mandal",
  4515: "Mandal",
  4516: "Mandal",
  4517: "Mandal",
  4519: "Holum",
  4520: "Lindesnes",
  4521: "Lindesnes",
  4522: "Lindesnes",
  4523: "Lindesnes",
  4524: "Lindesnes",
  4525: "Konsmo",
  4528: "Kollungtveit",
  4529: "Byremo",
  4532: "Øyslebø",
  4534: "Marnardal",
  4536: "Bjelland",
  4540: "Åseral",
  4544: "Fossdal",
  4550: "Farsund",
  4551: "Farsund",
  4552: "Farsund",
  4553: "Farsund",
  4554: "Farsund",
  4557: "Vanse",
  4558: "Vanse",
  4560: "Vanse",
  4563: "Borhaug",
  4575: "Lyngdal",
  4576: "Lyngdal",
  4577: "Lyngdal",
  4579: "Lyngdal",
  4580: "Lyngdal",
  4586: "Korshamn",
  4588: "Kvås",
  4590: "Snartemo",
  4595: "Tingvatn",
  4596: "Eiken",
  4604: "Kristiansand S",
  4605: "Kristiansand S",
  4606: "Kristiansand S",
  4608: "Kristiansand S",
  4609: "Kardemomme By",
  4610: "Kristiansand S",
  4611: "Kristiansand S",
  4612: "Kristiansand S",
  4613: "Kristiansand S",
  4614: "Kristiansand S",
  4615: "Kristiansand S",
  4616: "Kristiansand S",
  4617: "Kristiansand S",
  4618: "Kristiansand S",
  4619: "Mosby",
  4620: "Kristiansand S",
  4621: "Kristiansand S",
  4622: "Kristiansand S",
  4623: "Kristiansand S",
  4624: "Kristiansand S",
  4625: "Flekkerøy",
  4626: "Kristiansand S",
  4628: "Kristiansand S",
  4629: "Kristiansand S",
  4630: "Kristiansand S",
  4631: "Kristiansand S",
  4632: "Kristiansand S",
  4633: "Kristiansand S",
  4634: "Kristiansand S",
  4635: "Kristiansand S",
  4636: "Kristiansand S",
  4637: "Kristiansand S",
  4638: "Kristiansand S",
  4639: "Kristiansand S",
  4640: "Søgne",
  4641: "Søgne",
  4642: "Søgne",
  4643: "Søgne",
  4644: "Søgne",
  4645: "Nodeland",
  4646: "Finsland",
  4647: "Brennåsen",
  4651: "Hamresanden",
  4656: "Hamresanden",
  4657: "Kjevik",
  4658: "Tveit",
  4661: "Kristiansand S",
  4662: "Kristiansand S",
  4663: "Kristiansand S",
  4664: "Kristiansand S",
  4665: "Kristiansand S",
  4666: "Kristiansand S",
  4670: "Kristiansand S",
  4671: "Kristiansand S",
  4672: "Kristiansand S",
  4673: "Kristiansand S",
  4674: "Kristiansand S",
  4675: "Kristiansand S",
  4676: "Kristiansand S",
  4677: "Kristiansand S",
  4678: "Kristiansand S",
  4679: "Flekkerøy",
  4681: "Søgne",
  4682: "Søgne",
  4683: "Søgne",
  4684: "Brennåsen",
  4685: "Nodeland",
  4686: "Kristiansand S",
  4687: "Kristiansand S",
  4688: "Kristiansand S",
  4689: "Kristiansand S",
  4691: "Kristiansand S",
  4693: "Kristiansand S",
  4694: "Kristiansand S",
  4695: "Kristiansand S",
  4696: "Kristiansand S",
  4697: "Kristiansand S",
  4698: "Kristiansand S",
  4699: "Tveit",
  4700: "Vennesla",
  4701: "Vennesla",
  4702: "Vennesla",
  4703: "Vennesla",
  4705: "Øvrebø",
  4706: "Vennesla",
  4707: "Vennesla",
  4708: "Vennesla",
  4715: "Øvrebø",
  4720: "Hægeland",
  4724: "Iveland",
  4730: "Vatnestrøm",
  4733: "Evje",
  4734: "Evje",
  4735: "Evje",
  4737: "Hornnes",
  4741: "Byglandsfjord",
  4742: "Grendi",
  4745: "Bygland",
  4747: "Valle",
  4748: "Rysstad",
  4754: "Bykle",
  4755: "Hovden I Setesdal",
  4756: "Hovden I Setesdal",
  4760: "Birkeland",
  4766: "Herefoss",
  4768: "Engesland",
  4770: "Høvåg",
  4780: "Brekkestø",
  4790: "Lillesand",
  4791: "Lillesand",
  4792: "Lillesand",
  4793: "Høvåg",
  4794: "Lillesand",
  4795: "Birkeland",
  4801: "Arendal",
  4802: "Arendal",
  4803: "Arendal",
  4804: "Arendal",
  4808: "Arendal",
  4809: "Arendal",
  4810: "Eydehavn",
  4812: "Kongshavn",
  4815: "Saltrød",
  4816: "Kolbjørnsvik",
  4817: "His",
  4818: "Færvik",
  4820: "Froland",
  4821: "Rykene",
  4823: "Nedenes",
  4824: "Bjorbekk",
  4825: "Arendal",
  4827: "Frolands Verk",
  4828: "Mjåvatn",
  4830: "Hynnekleiv",
  4832: "Mykland",
  4834: "Risdal",
  4836: "Arendal",
  4838: "Arendal",
  4839: "Arendal",
  4841: "Arendal",
  4842: "Arendal",
  4843: "Arendal",
  4844: "Arendal",
  4846: "Arendal",
  4847: "Arendal",
  4848: "Arendal",
  4849: "Arendal",
  4851: "Saltrød",
  4852: "Færvik",
  4853: "His",
  4854: "Nedenes",
  4855: "Froland",
  4856: "Arendal",
  4857: "Arendal",
  4858: "Arendal",
  4859: "Arendal",
  4861: "Arendal",
  4862: "Eydehavn",
  4863: "Nelaug",
  4864: "Åmli",
  4865: "Åmli",
  4868: "Selåsvatn",
  4869: "Dølemo",
  4870: "Fevik",
  4876: "Grimstad",
  4877: "Grimstad",
  4878: "Grimstad",
  4879: "Grimstad",
  4884: "Grimstad",
  4885: "Grimstad",
  4886: "Grimstad",
  4887: "Grimstad",
  4888: "Homborsund",
  4889: "Fevik",
  4891: "Grimstad",
  4892: "Grimstad",
  4893: "Grimstad",
  4894: "Grimstad",
  4896: "Grimstad",
  4898: "Grimstad",
  4900: "Tvedestrand",
  4901: "Tvedestrand",
  4902: "Tvedestrand",
  4909: "Songe",
  4910: "Lyngør",
  4912: "Gjeving",
  4915: "Vestre Sandøya",
  4916: "Borøy",
  4920: "Staubø",
  4934: "Nes Verk",
  4950: "Risør",
  4951: "Risør",
  4952: "Risør",
  4953: "Risør",
  4955: "Risør",
  4956: "Risør",
  4957: "Risør",
  4971: "Sundebru",
  4972: "Gjerstad",
  4973: "Vegårshei",
  4974: "Søndeled",
  4980: "Gjerstad",
  4985: "Vegårshei",
  4990: "Søndeled",
  4993: "Sundebru",
  4994: "Akland",
  5003: "Bergen",
  5004: "Bergen",
  5005: "Bergen",
  5006: "Bergen",
  5007: "Bergen",
  5008: "Bergen",
  5009: "Bergen",
  5010: "Bergen",
  5011: "Bergen",
  5012: "Bergen",
  5013: "Bergen",
  5014: "Bergen",
  5015: "Bergen",
  5016: "Bergen",
  5017: "Bergen",
  5018: "Bergen",
  5019: "Bergen",
  5020: "Bergen",
  5021: "Bergen",
  5022: "Bergen",
  5031: "Bergen",
  5032: "Bergen",
  5033: "Bergen",
  5034: "Bergen",
  5035: "Bergen",
  5036: "Bergen",
  5037: "Bergen",
  5038: "Bergen",
  5039: "Bergen",
  5041: "Bergen",
  5042: "Bergen",
  5043: "Bergen",
  5045: "Bergen",
  5052: "Bergen",
  5053: "Bergen",
  5054: "Bergen",
  5055: "Bergen",
  5056: "Bergen",
  5057: "Bergen",
  5058: "Bergen",
  5059: "Bergen",
  5063: "Bergen",
  5067: "Bergen",
  5068: "Bergen",
  5072: "Bergen",
  5073: "Bergen",
  5075: "Bergen",
  5081: "Bergen",
  5082: "Bergen",
  5089: "Bergen",
  5093: "Bergen",
  5094: "Bergen",
  5096: "Bergen",
  5097: "Bergen",
  5098: "Bergen",
  5099: "Bergen",
  5101: "Eidsvågneset",
  5104: "Eidsvåg I Åsane",
  5105: "Eidsvåg I Åsane",
  5106: "Øvre Ervik",
  5107: "Salhus",
  5108: "Hordvik",
  5109: "Hylkje",
  5111: "Breistein",
  5113: "Tertnes",
  5114: "Tertnes",
  5115: "Ulset",
  5116: "Ulset",
  5117: "Ulset",
  5118: "Ulset",
  5119: "Ulset",
  5121: "Ulset",
  5122: "Morvik",
  5124: "Morvik",
  5130: "Nyborg",
  5131: "Nyborg",
  5132: "Nyborg",
  5134: "Flaktveit",
  5135: "Flaktveit",
  5136: "Mjølkeråen",
  5137: "Mjølkeråen",
  5141: "Fyllingsdalen",
  5142: "Fyllingsdalen",
  5143: "Fyllingsdalen",
  5144: "Fyllingsdalen",
  5145: "Fyllingsdalen",
  5146: "Fyllingsdalen",
  5147: "Fyllingsdalen",
  5148: "Fyllingsdalen",
  5151: "Straumsgrend",
  5152: "Bønes",
  5153: "Bønes",
  5154: "Bønes",
  5155: "Bønes",
  5160: "Laksevåg",
  5161: "Laksevåg",
  5162: "Laksevåg",
  5163: "Laksevåg",
  5164: "Laksevåg",
  5165: "Laksevåg",
  5170: "Bjørndalstræ",
  5171: "Loddefjord",
  5172: "Loddefjord",
  5173: "Loddefjord",
  5174: "Mathopen",
  5176: "Loddefjord",
  5177: "Bjørøyhamn",
  5178: "Loddefjord",
  5179: "Godvik",
  5183: "Olsvik",
  5184: "Olsvik",
  5200: "Os",
  5201: "Os",
  5202: "Os",
  5203: "Os",
  5206: "Os",
  5207: "Søfteland",
  5208: "Os",
  5209: "Os",
  5210: "Os",
  5211: "Os",
  5212: "Søfteland",
  5213: "Lepsøy",
  5214: "Lysekloster",
  5215: "Lysekloster",
  5216: "Lepsøy",
  5217: "Hagavik",
  5218: "Nordstrøno",
  5221: "Nesttun",
  5222: "Nesttun",
  5223: "Nesttun",
  5224: "Nesttun",
  5225: "Nesttun",
  5226: "Nesttun",
  5227: "Nesttun",
  5228: "Nesttun",
  5229: "Kalandseidet",
  5230: "Paradis",
  5231: "Paradis",
  5232: "Paradis",
  5235: "Rådal",
  5236: "Rådal",
  5237: "Rådal",
  5238: "Rådal",
  5239: "Rådal",
  5243: "Fana",
  5244: "Fana",
  5251: "Søreidgrend",
  5252: "Søreidgrend",
  5253: "Sandsli",
  5254: "Sandsli",
  5257: "Kokstad",
  5258: "Blomsterdalen",
  5259: "Hjellestad",
  5260: "Indre Arna",
  5261: "Indre Arna",
  5262: "Arnatveit",
  5263: "Trengereid",
  5264: "Garnes",
  5265: "Ytre Arna",
  5267: "Espeland",
  5268: "Haukeland",
  5281: "Valestrandsfossen",
  5282: "Lonevåg",
  5283: "Fotlandsvåg",
  5284: "Tyssebotnen",
  5285: "Bruvik",
  5286: "Haus",
  5291: "Valestrandsfossen",
  5293: "Lonevåg",
  5299: "Haus",
  5300: "Kleppestø",
  5301: "Kleppestø",
  5302: "Strusshamn",
  5303: "Follese",
  5304: "Hetlevik",
  5305: "Florvåg",
  5306: "Erdal",
  5307: "Ask",
  5308: "Kleppestø",
  5309: "Kleppestø",
  5310: "Hauglandshella",
  5311: "Kjerrgarden",
  5314: "Kjerrgarden",
  5315: "Herdla",
  5318: "Strusshamn",
  5319: "Kleppestø",
  5321: "Kleppestø",
  5322: "Kleppestø",
  5323: "Kleppestø",
  5325: "Follese",
  5326: "Ask",
  5327: "Hauglandshella",
  5329: "Florvåg",
  5331: "Rong",
  5333: "Tjeldstø",
  5334: "Hellesøy",
  5335: "Hernar",
  5336: "Tjeldstø",
  5337: "Rong",
  5341: "Straume",
  5342: "Straume",
  5343: "Straume",
  5345: "Knarrevik",
  5346: "Ågotnes",
  5347: "Ågotnes",
  5350: "Brattholmen",
  5353: "Straume",
  5354: "Straume",
  5355: "Knarrevik",
  5357: "Fjell",
  5358: "Fjell",
  5360: "Kolltveit",
  5363: "Ågotnes",
  5365: "Turøy",
  5366: "Misje",
  5371: "Skogsvåg",
  5374: "Steinsland",
  5378: "Klokkarvik",
  5379: "Steinsland",
  5380: "Tælavåg",
  5381: "Glesvær",
  5382: "Skogsvåg",
  5384: "Torangsvåg",
  5385: "Bakkasund",
  5387: "Møkster",
  5388: "Litlakalsøy",
  5392: "Storebø",
  5393: "Storebø",
  5394: "Kolbeinsvik",
  5396: "Vestre Vinnesvåg",
  5397: "Bekkjarvik",
  5398: "Stolmen",
  5399: "Bekkjarvik",
  5401: "Stord",
  5402: "Stord",
  5403: "Stord",
  5404: "Stord",
  5406: "Stord",
  5407: "Stord",
  5408: "Sagvåg",
  5409: "Stord",
  5410: "Sagvåg",
  5411: "Stord",
  5412: "Stord",
  5413: "Huglo",
  5414: "Stord",
  5415: "Stord",
  5416: "Stord",
  5417: "Stord",
  5418: "Fitjar",
  5419: "Fitjar",
  5420: "Rubbestadneset",
  5423: "Brandasund",
  5427: "Urangsvåg",
  5428: "Foldrøyhamn",
  5430: "Bremnes",
  5437: "Finnås",
  5440: "Mosterhamn",
  5443: "Bømlo",
  5444: "Espevær",
  5445: "Bremnes",
  5447: "Mosterhamn",
  5449: "Bømlo",
  5450: "Sunde I Sunnhordland",
  5451: "Valen",
  5452: "Sandvoll",
  5453: "Utåker",
  5454: "Sæbøvik",
  5455: "Halsnøy Kloster",
  5457: "Høylandsbygd",
  5458: "Arnavik",
  5459: "Fjelberg",
  5460: "Husnes",
  5462: "Herøysundet",
  5463: "Uskedalen",
  5464: "Dimmelsvik",
  5465: "Uskedalen",
  5470: "Rosendal",
  5472: "Seimsfoss",
  5473: "Snilstveitøy",
  5474: "Løfallstrand",
  5475: "Ænes",
  5476: "Mauranger",
  5480: "Husnes",
  5484: "Sæbøvik",
  5486: "Rosendal",
  5498: "Matre",
  5499: "Åkra",
  5501: "Haugesund",
  5502: "Haugesund",
  5503: "Haugesund",
  5504: "Haugesund",
  5505: "Haugesund",
  5506: "Haugesund",
  5507: "Haugesund",
  5508: "Karmsund",
  5509: "Haugesund",
  5511: "Haugesund",
  5512: "Haugesund",
  5514: "Haugesund",
  5515: "Haugesund",
  5516: "Haugesund",
  5517: "Haugesund",
  5518: "Haugesund",
  5519: "Haugesund",
  5521: "Haugesund",
  5522: "Haugesund",
  5523: "Haugesund",
  5525: "Haugesund",
  5527: "Haugesund",
  5528: "Haugesund",
  5529: "Haugesund",
  5531: "Haugesund",
  5532: "Haugesund",
  5533: "Haugesund",
  5534: "Haugesund",
  5535: "Haugesund",
  5536: "Haugesund",
  5537: "Haugesund",
  5538: "Haugesund",
  5541: "Kolnes",
  5542: "Karmsund",
  5544: "Vormedal",
  5545: "Vormedal",
  5546: "Røyksund",
  5547: "Utsira",
  5548: "Feøy",
  5549: "Røvær",
  5550: "Sveio",
  5551: "Auklandshamn",
  5554: "Valevåg",
  5555: "Førde I Hordaland",
  5559: "Sveio",
  5560: "Nedstrand",
  5561: "Bokn",
  5562: "Nedstrand",
  5563: "Førresfjorden",
  5565: "Tysværvåg",
  5566: "Hervik",
  5567: "Skjoldastraumen",
  5568: "Vikebygd",
  5570: "Aksdal",
  5574: "Skjold",
  5575: "Aksdal",
  5576: "Øvre Vats",
  5578: "Nedre Vats",
  5580: "Ølen",
  5582: "Ølensvåg",
  5583: "Vikedal",
  5584: "Bjoa",
  5585: "Sandeid",
  5586: "Vikedal",
  5588: "Ølen",
  5589: "Sandeid",
  5590: "Etne",
  5591: "Etne",
  5593: "Skånevik",
  5594: "Skånevik",
  5595: "Førresfjorden",
  5596: "Markhus",
  5598: "Fjæra",
  5600: "Norheimsund",
  5601: "Norheimsund",
  5602: "Norheimsund",
  5604: "Øystese",
  5605: "Ålvik",
  5610: "Øystese",
  5612: "Steinstø",
  5614: "Ålvik",
  5620: "Tørvikbygd",
  5626: "Kysnesstrand",
  5627: "Jondal",
  5628: "Herand",
  5629: "Jondal",
  5630: "Strandebarm",
  5631: "Strandebarm",
  5632: "Omastrand",
  5633: "Omastrand",
  5635: "Hatlestrand",
  5636: "Varaldsøy",
  5637: "Ølve",
  5640: "Eikelandsosen",
  5641: "Fusa",
  5642: "Holmefjord",
  5643: "Strandvik",
  5644: "Sævareid",
  5645: "Sævareid",
  5646: "Nordtveitgrend",
  5647: "Baldersheim",
  5648: "Fusa",
  5649: "Eikelandsosen",
  5650: "Tysse",
  5651: "Tysse",
  5652: "Årland",
  5653: "Årland",
  5680: "Tysnes",
  5683: "Reksteren",
  5685: "Uggdal",
  5687: "Flatråker",
  5690: "Lundegrend",
  5693: "Årbakka",
  5694: "Onarheim",
  5695: "Uggdal",
  5696: "Tysnes",
  5700: "Voss",
  5701: "Voss",
  5702: "Voss",
  5703: "Voss",
  5704: "Voss",
  5705: "Voss",
  5706: "Voss",
  5707: "Evanger",
  5708: "Voss",
  5709: "Voss",
  5710: "Skulestadmo",
  5711: "Skulestadmo",
  5712: "Vossestrand",
  5713: "Vossestrand",
  5714: "Voss",
  5715: "Stalheim",
  5718: "Myrdal",
  5719: "Finse",
  5720: "Stanghelle",
  5721: "Dalekvam",
  5722: "Dalekvam",
  5723: "Bolstadøyri",
  5724: "Stanghelle",
  5725: "Vaksdal",
  5726: "Vaksdal",
  5727: "Stamnes",
  5728: "Eidslandet",
  5729: "Modalen",
  5730: "Ulvik",
  5731: "Ulvik",
  5732: "Modalen",
  5733: "Granvin",
  5734: "Vallavik",
  5736: "Granvin",
  5741: "Aurland",
  5742: "Flåm",
  5743: "Flåm",
  5745: "Aurland",
  5746: "Undredal",
  5747: "Gudvangen",
  5748: "Styvi",
  5749: "Bakka",
  5750: "Odda",
  5751: "Odda",
  5752: "Odda",
  5760: "Røldal",
  5763: "Skare",
  5770: "Tyssedal",
  5773: "Hovland",
  5776: "Nå",
  5777: "Grimo",
  5778: "Utne",
  5779: "Utne",
  5780: "Kinsarvik",
  5781: "Lofthus",
  5782: "Kinsarvik",
  5783: "Eidfjord",
  5784: "Øvre Eidfjord",
  5785: "Vøringsfoss",
  5786: "Eidfjord",
  5787: "Lofthus",
  5788: "Kinsarvik",
  5802: "Bergen",
  5803: "Bergen",
  5804: "Bergen",
  5805: "Bergen",
  5806: "Bergen",
  5807: "Bergen",
  5808: "Bergen",
  5809: "Bergen",
  5810: "Bergen",
  5811: "Bergen",
  5812: "Bergen",
  5813: "Bergen",
  5814: "Bergen",
  5815: "Bergen",
  5816: "Bergen",
  5817: "Bergen",
  5818: "Bergen",
  5819: "Bergen",
  5820: "Bergen",
  5821: "Bergen",
  5822: "Bergen",
  5823: "Bergen",
  5824: "Bergen",
  5825: "Bergen",
  5826: "Bergen",
  5827: "Bergen",
  5828: "Bergen",
  5829: "Bergen",
  5830: "Bergen",
  5831: "Bergen",
  5832: "Bergen",
  5833: "Bergen",
  5834: "Bergen",
  5835: "Bergen",
  5836: "Bergen",
  5837: "Bergen",
  5838: "Bergen",
  5841: "Bergen",
  5843: "Bergen",
  5844: "Bergen",
  5845: "Bergen",
  5847: "Bergen",
  5848: "Bergen",
  5849: "Bergen",
  5851: "Bergen",
  5852: "Bergen",
  5853: "Bergen",
  5854: "Bergen",
  5855: "Bergen",
  5857: "Bergen",
  5858: "Bergen",
  5859: "Bergen",
  5861: "Bergen",
  5862: "Bergen",
  5863: "Bergen",
  5864: "Bergen",
  5865: "Bergen",
  5866: "Bergen",
  5867: "Bergen",
  5868: "Bergen",
  5869: "Bergen",
  5872: "Bergen",
  5873: "Bergen",
  5876: "Bergen",
  5877: "Bergen",
  5878: "Bergen",
  5879: "Bergen",
  5881: "Bergen",
  5884: "Bergen",
  5886: "Bergen",
  5887: "Bergen",
  5888: "Bergen",
  5889: "Bergen",
  5892: "Bergen",
  5893: "Bergen",
  5895: "Bergen",
  5896: "Bergen",
  5899: "Bergen",
  5902: "Isdalstø",
  5903: "Isdalstø",
  5904: "Isdalstø",
  5906: "Frekhaug",
  5907: "Alversund",
  5908: "Isdalstø",
  5911: "Alversund",
  5912: "Seim",
  5913: "Eikangervåg",
  5914: "Isdalstø",
  5915: "Hjelmås",
  5916: "Isdalstø",
  5917: "Rossland",
  5918: "Frekhaug",
  5931: "Manger",
  5936: "Manger",
  5937: "Bøvågen",
  5938: "Sæbøvågen",
  5939: "Sletta",
  5941: "Austrheim",
  5943: "Austrheim",
  5947: "Fedje",
  5948: "Fedje",
  5951: "Lindås",
  5952: "Fonnes",
  5953: "Fonnes",
  5954: "Mongstad",
  5955: "Lindås",
  5956: "Hundvin",
  5957: "Myking",
  5960: "Dalsøyra",
  5961: "Brekke",
  5962: "Bjordal",
  5966: "Eivindvik",
  5967: "Eivindvik",
  5970: "Byrknesøy",
  5977: "Ånneland",
  5978: "Mjømna",
  5979: "Byrknesøy",
  5981: "Masfjordnes",
  5982: "Masfjordnes",
  5983: "Haugsvær",
  5984: "Matredal",
  5986: "Hosteland",
  5987: "Hosteland",
  5991: "Ostereidet",
  5993: "Ostereidet",
  5994: "Vikanes",
  6001: "Ålesund",
  6002: "Ålesund",
  6003: "Ålesund",
  6004: "Ålesund",
  6005: "Ålesund",
  6006: "Ålesund",
  6007: "Ålesund",
  6008: "Ålesund",
  6009: "Ålesund",
  6010: "Ålesund",
  6011: "Ålesund",
  6012: "Ålesund",
  6013: "Ålesund",
  6014: "Ålesund",
  6015: "Ålesund",
  6016: "Ålesund",
  6017: "Ålesund",
  6018: "Ålesund",
  6019: "Ålesund",
  6020: "Ålesund",
  6021: "Ålesund",
  6022: "Ålesund",
  6023: "Ålesund",
  6024: "Ålesund",
  6025: "Ålesund",
  6026: "Ålesund",
  6028: "Ålesund",
  6030: "Langevåg",
  6035: "Fiskarstrand",
  6036: "Mauseidvåg",
  6037: "Eidsnes",
  6038: "Fiskarstrand",
  6039: "Langevåg",
  6040: "Vigra",
  6044: "Ålesund",
  6045: "Ålesund",
  6046: "Ålesund",
  6047: "Ålesund",
  6048: "Ålesund",
  6050: "Valderøya",
  6051: "Valderøya",
  6052: "Giske",
  6055: "Godøya",
  6057: "Ellingsøy",
  6058: "Valderøya",
  6059: "Vigra",
  6060: "Hareid",
  6062: "Brandal",
  6063: "Hjørungavåg",
  6064: "Haddal",
  6065: "Ulsteinvik",
  6067: "Ulsteinvik",
  6068: "Eiksund",
  6069: "Hareid",
  6070: "Tjørvåg",
  6076: "Moltustranda",
  6080: "Gurskøy",
  6082: "Gursken",
  6083: "Gjerdsvika",
  6084: "Larsnes",
  6085: "Larsnes",
  6087: "Kvamsøy",
  6089: "Sandshamn",
  6090: "Fosnavåg",
  6091: "Fosnavåg",
  6092: "Fosnavåg",
  6094: "Leinøy",
  6095: "Bølandet",
  6096: "Runde",
  6098: "Nerlandsøy",
  6099: "Fosnavåg",
  6100: "Volda",
  6101: "Volda",
  6102: "Volda",
  6103: "Volda",
  6104: "Volda",
  6105: "Volda",
  6106: "Volda",
  6110: "Austefjorden",
  6120: "Folkestad",
  6133: "Lauvstad",
  6139: "Fiskå",
  6140: "Syvde",
  6141: "Rovde",
  6142: "Eidså",
  6143: "Fiskå",
  6144: "Sylte",
  6146: "Åheim",
  6149: "Åram",
  6150: "Ørsta",
  6151: "Ørsta",
  6152: "Ørsta",
  6153: "Ørsta",
  6154: "Ørsta",
  6155: "Ørsta",
  6156: "Ørsta",
  6160: "Hovdebygda",
  6161: "Hovdebygda",
  6165: "Sæbø",
  6166: "Sæbø",
  6170: "Vartdal",
  6174: "Barstadvik",
  6183: "Trandal",
  6184: "Storestandal",
  6190: "Bjørke",
  6196: "Norangsfjorden",
  6200: "Stranda",
  6201: "Stranda",
  6210: "Valldal",
  6211: "Valldal",
  6212: "Liabygda",
  6213: "Tafjord",
  6214: "Norddal",
  6215: "Eidsdal",
  6216: "Geiranger",
  6218: "Hellesylt",
  6220: "Straumgjerde",
  6222: "Ikornnes",
  6224: "Hundeidvik",
  6230: "Sykkylven",
  6238: "Straumgjerde",
  6239: "Sykkylven",
  6240: "Ørskog",
  6249: "Ørskog",
  6250: "Stordal",
  6259: "Stordal",
  6260: "Skodje",
  6263: "Skodje",
  6264: "Tennfjord",
  6265: "Vatne",
  6270: "Brattvåg",
  6272: "Hildre",
  6280: "Søvik",
  6281: "Søvik",
  6282: "Brattvåg",
  6283: "Vatne",
  6285: "Storekalvøy",
  6290: "Haramsøy",
  6292: "Kjerstad",
  6293: "Longva",
  6294: "Fjørtoft",
  6300: "Åndalsnes",
  6301: "Åndalsnes",
  6310: "Veblungsnes",
  6315: "Innfjorden",
  6320: "Isfjorden",
  6330: "Verma",
  6339: "Isfjorden",
  6350: "Eidsbygda",
  6360: "Åfarnes",
  6363: "Mittet",
  6364: "Vistdal",
  6386: "Måndalen",
  6387: "Vågstranda",
  6390: "Vestnes",
  6391: "Tresfjord",
  6392: "Vikebukt",
  6393: "Tomrefjord",
  6394: "Fiksdal",
  6395: "Rekdal",
  6396: "Vikebukt",
  6397: "Tresfjord",
  6398: "Tomrefjord",
  6399: "Vestnes",
  6401: "Molde",
  6402: "Molde",
  6403: "Molde",
  6404: "Molde",
  6405: "Molde",
  6407: "Molde",
  6408: "Aureosen",
  6409: "Molde",
  6410: "Molde",
  6411: "Molde",
  6412: "Molde",
  6413: "Molde",
  6414: "Molde",
  6415: "Molde",
  6416: "Molde",
  6418: "Sekken",
  6419: "Molde",
  6421: "Molde",
  6422: "Molde",
  6423: "Molde",
  6425: "Molde",
  6429: "Molde",
  6430: "Bud",
  6431: "Bud",
  6433: "Hustad",
  6434: "Molde",
  6435: "Molde",
  6436: "Molde",
  6440: "Elnesvågen",
  6443: "Tornes I Romsdal",
  6444: "Farstad",
  6445: "Malmefjorden",
  6447: "Elnesvågen",
  6450: "Hjelset",
  6453: "Kleive",
  6454: "Hjelset",
  6455: "Kortgarden",
  6456: "Skåla",
  6457: "Bolsøya",
  6460: "Eidsvåg I Romsdal",
  6461: "Eidsvåg I Romsdal",
  6462: "Raudsand",
  6470: "Eresfjord",
  6472: "Eikesdal",
  6475: "Midsund",
  6476: "Midsund",
  6480: "Aukra",
  6481: "Aukra",
  6483: "Ona",
  6484: "Sandøy",
  6486: "Orten",
  6487: "Harøy",
  6488: "Myklebost",
  6490: "Eide",
  6493: "Lyngstad",
  6494: "Vevang",
  6499: "Eide",
  6501: "Kristiansund N",
  6502: "Kristiansund N",
  6503: "Kristiansund N",
  6504: "Kristiansund N",
  6506: "Kristiansund N",
  6507: "Kristiansund N",
  6508: "Kristiansund N",
  6509: "Kristiansund N",
  6510: "Kristiansund N",
  6511: "Kristiansund N",
  6512: "Kristiansund N",
  6514: "Kristiansund N",
  6515: "Kristiansund N",
  6516: "Kristiansund N",
  6517: "Kristiansund N",
  6518: "Kristiansund N",
  6520: "Frei",
  6521: "Frei",
  6522: "Frei",
  6523: "Frei",
  6524: "Frei",
  6525: "Frei",
  6529: "Frei",
  6530: "Averøy",
  6531: "Averøy",
  6532: "Averøy",
  6533: "Averøy",
  6538: "Averøy",
  6539: "Averøy",
  6546: "Kristiansund N",
  6547: "Kristiansund N",
  6548: "Kristiansund N",
  6549: "Kristiansund N",
  6570: "Smøla",
  6571: "Smøla",
  6590: "Tustna",
  6600: "Sunndalsøra",
  6601: "Sunndalsøra",
  6610: "Øksendal",
  6611: "Furugrenda",
  6612: "Grøa",
  6613: "Gjøra",
  6620: "Ålvundeid",
  6622: "Ålvundfjord",
  6628: "Meisingset",
  6629: "Torjulvågen",
  6630: "Tingvoll",
  6631: "Batnfjordsøra",
  6633: "Gjemnes",
  6636: "Angvik",
  6637: "Flemma",
  6638: "Osmarka",
  6639: "Torvikbukt",
  6640: "Kvanne",
  6642: "Stangvik",
  6643: "Bøfjorden",
  6644: "Bæverfjord",
  6645: "Todalen",
  6650: "Surnadal",
  6652: "Surna",
  6653: "Øvre Surnadal",
  6655: "Vindøla",
  6656: "Surnadal",
  6657: "Rindal",
  6658: "Rindalsskogen",
  6659: "Rindal",
  6670: "Øydegard",
  6674: "Kvisvik",
  6680: "Halsanaustan",
  6683: "Vågland",
  6686: "Valsøybotn",
  6687: "Valsøyfjord",
  6688: "Vågland",
  6689: "Aure",
  6690: "Aure",
  6693: "Mjosundet",
  6694: "Foldfjorden",
  6697: "Vihals",
  6698: "Lesund",
  6699: "Kjørsvikbugen",
  6700: "Måløy",
  6701: "Måløy",
  6702: "Måløy",
  6703: "Måløy",
  6704: "Deknepollen",
  6707: "Raudeberg",
  6708: "Bryggja",
  6710: "Raudeberg",
  6711: "Bryggja",
  6713: "Almenningen",
  6714: "Silda",
  6715: "Barmen",
  6716: "Husevåg",
  6717: "Flatraket",
  6718: "Deknepollen",
  6719: "Skatestraumen",
  6721: "Svelgen",
  6723: "Svelgen",
  6726: "Bremanger",
  6727: "Bremanger",
  6728: "Kalvåg",
  6729: "Kalvåg",
  6730: "Davik",
  6734: "Rugsund",
  6737: "Ålfoten",
  6740: "Selje",
  6741: "Selje",
  6750: "Stadlandet",
  6751: "Stadlandet",
  6761: "Hornindal",
  6763: "Hornindal",
  6770: "Nordfjordeid",
  6771: "Nordfjordeid",
  6772: "Nordfjordeid",
  6776: "Kjølsdalen",
  6777: "Stårheim",
  6778: "Lote",
  6779: "Holmøyane",
  6781: "Stryn",
  6782: "Stryn",
  6783: "Stryn",
  6784: "Olden",
  6788: "Olden",
  6789: "Loen",
  6791: "Oldedalen",
  6792: "Briksdalsbre",
  6793: "Innvik",
  6795: "Blaksæter",
  6796: "Hopland",
  6797: "Utvik",
  6798: "Hjelledalen",
  6799: "Oppstryn",
  6800: "Førde",
  6801: "Førde",
  6802: "Førde",
  6803: "Førde",
  6804: "Førde",
  6805: "Førde",
  6806: "Naustdal",
  6807: "Førde",
  6808: "Førde",
  6809: "Førde",
  6810: "Førde",
  6811: "Førde",
  6812: "Førde",
  6813: "Førde",
  6814: "Førde",
  6815: "Førde",
  6817: "Naustdal",
  6818: "Haukedalen",
  6819: "Førde",
  6820: "Førde",
  6821: "Sandane",
  6822: "Sandane",
  6823: "Sandane",
  6826: "Byrkjelo",
  6827: "Breim",
  6828: "Hestenesøyra",
  6829: "Hyen",
  6841: "Skei I Jølster",
  6843: "Skei I Jølster",
  6847: "Vassenden",
  6848: "Fjærland",
  6849: "Kaupanger",
  6851: "Sogndal",
  6852: "Sogndal",
  6853: "Sogndal",
  6854: "Kaupanger",
  6855: "Frønningen",
  6856: "Sogndal",
  6858: "Fardal",
  6859: "Slinde",
  6861: "Leikanger",
  6863: "Leikanger",
  6866: "Gaupne",
  6868: "Gaupne",
  6869: "Hafslo",
  6870: "Ornes",
  6871: "Jostedal",
  6872: "Luster",
  6873: "Marifjøra",
  6875: "Høyheimsvik",
  6876: "Skjolden",
  6877: "Fortun",
  6878: "Veitastrond",
  6879: "Solvorn",
  6881: "Årdalstangen",
  6882: "Øvre Årdal",
  6884: "Øvre Årdal",
  6885: "Årdalstangen",
  6886: "Lærdal",
  6887: "Lærdal",
  6888: "Borgund",
  6891: "Vik I Sogn",
  6893: "Vik I Sogn",
  6894: "Vangsnes",
  6895: "Feios",
  6896: "Fresvik",
  6898: "Balestrand",
  6899: "Balestrand",
  6900: "Florø",
  6901: "Florø",
  6902: "Florø",
  6903: "Florø",
  6905: "Florø",
  6906: "Florø",
  6907: "Florø",
  6908: "Florø",
  6909: "Florø",
  6910: "Florø",
  6912: "Kinn",
  6913: "Florø",
  6914: "Svanøybukt",
  6915: "Rognaldsvåg",
  6916: "Barekstad",
  6917: "Batalden",
  6918: "Sør-Skorpa",
  6919: "Tansøy",
  6921: "Hardbakke",
  6924: "Hardbakke",
  6926: "Krakhella",
  6927: "Ytrøygrend",
  6928: "Kolgrov",
  6929: "Hersvikbygda",
  6940: "Eikefjord",
  6941: "Eikefjord",
  6942: "Svortevik",
  6944: "Stavang",
  6946: "Lavik",
  6947: "Lavik",
  6951: "Leirvik I Sogn",
  6953: "Leirvik I Sogn",
  6957: "Hyllestad",
  6958: "Sørbøvåg",
  6961: "Dale I Sunnfjord",
  6963: "Dale I Sunnfjord",
  6964: "Korssund",
  6966: "Guddal",
  6967: "Hellevik I Fjaler",
  6968: "Flekke",
  6969: "Straumsnes",
  6971: "Sande I Sunnfjord",
  6973: "Sande I Sunnfjord",
  6975: "Skilbrei",
  6977: "Bygstad",
  6978: "Viksdalen",
  6980: "Askvoll",
  6981: "Holmedal",
  6982: "Holmedal",
  6983: "Kvammen",
  6984: "Stongfjorden",
  6985: "Atløy",
  6986: "Værlandet",
  6987: "Bulandet",
  6988: "Askvoll",
  6991: "Høyanger",
  6993: "Høyanger",
  6995: "Kyrkjebø",
  6996: "Vadheim",
  7003: "Trondheim",
  7004: "Trondheim",
  7005: "Trondheim",
  7006: "Trondheim",
  7010: "Trondheim",
  7011: "Trondheim",
  7012: "Trondheim",
  7013: "Trondheim",
  7014: "Trondheim",
  7015: "Trondheim",
  7016: "Trondheim",
  7017: "Trondheim",
  7018: "Trondheim",
  7019: "Trondheim",
  7020: "Trondheim",
  7021: "Trondheim",
  7022: "Trondheim",
  7023: "Trondheim",
  7024: "Trondheim",
  7025: "Trondheim",
  7026: "Trondheim",
  7027: "Trondheim",
  7028: "Trondheim",
  7029: "Trondheim",
  7030: "Trondheim",
  7031: "Trondheim",
  7032: "Trondheim",
  7033: "Trondheim",
  7034: "Trondheim",
  7035: "Trondheim",
  7036: "Trondheim",
  7037: "Trondheim",
  7038: "Trondheim",
  7039: "Trondheim",
  7040: "Trondheim",
  7041: "Trondheim",
  7042: "Trondheim",
  7043: "Trondheim",
  7044: "Trondheim",
  7045: "Trondheim",
  7046: "Trondheim",
  7047: "Trondheim",
  7048: "Trondheim",
  7049: "Trondheim",
  7050: "Trondheim",
  7051: "Trondheim",
  7052: "Trondheim",
  7053: "Ranheim",
  7054: "Ranheim",
  7055: "Ranheim",
  7056: "Ranheim",
  7057: "Jonsvatnet",
  7058: "Jakobsli",
  7059: "Jakobsli",
  7066: "Trondheim",
  7067: "Trondheim",
  7068: "Trondheim",
  7069: "Trondheim",
  7070: "Bosberg",
  7071: "Trondheim",
  7072: "Heimdal",
  7074: "Spongdal",
  7075: "Tiller",
  7078: "Saupstad",
  7079: "Flatåsen",
  7080: "Heimdal",
  7081: "Sjetnemarka",
  7082: "Kattem",
  7083: "Leinstrand",
  7088: "Heimdal",
  7089: "Heimdal",
  7091: "Tiller",
  7092: "Tiller",
  7093: "Tiller",
  7097: "Saupstad",
  7098: "Saupstad",
  7099: "Flatåsen",
  7100: "Rissa",
  7101: "Rissa",
  7105: "Stadsbygd",
  7110: "Fevåg",
  7112: "Hasselvika",
  7113: "Husbysjøen",
  7114: "Råkvåg",
  7119: "Stadsbygd",
  7120: "Leksvik",
  7121: "Leksvik",
  7125: "Vanvikan",
  7126: "Vanvikan",
  7127: "Opphaug",
  7129: "Brekstad",
  7130: "Brekstad",
  7140: "Opphaug",
  7142: "Uthaug",
  7150: "Storfosna",
  7152: "Kråkvåg",
  7153: "Garten",
  7156: "Leksa",
  7159: "Bjugn",
  7160: "Bjugn",
  7165: "Oksvoll",
  7166: "Tarva",
  7167: "Vallersund",
  7168: "Lysøysundet",
  7169: "Åfjord",
  7170: "Åfjord",
  7176: "Linesøya",
  7177: "Revsnes",
  7178: "Stokkøy",
  7180: "Roan",
  7190: "Bessaker",
  7194: "Brandsfjord",
  7200: "Kyrksæterøra",
  7201: "Kyrksæterøra",
  7203: "Vinjeøra",
  7206: "Hellandsjøen",
  7211: "Korsvegen",
  7212: "Korsvegen",
  7213: "Gåsbakken",
  7221: "Melhus",
  7223: "Melhus",
  7224: "Melhus",
  7227: "Gimse",
  7228: "Kvål",
  7231: "Lundamo",
  7232: "Lundamo",
  7234: "Ler",
  7235: "Ler",
  7236: "Hovin I Gauldal",
  7238: "Hovin I Gauldal",
  7239: "Hitra",
  7240: "Hitra",
  7241: "Ansnes",
  7242: "Knarrlagsund",
  7243: "Kvenvær",
  7246: "Sandstad",
  7247: "Hestvika",
  7250: "Melandsjø",
  7252: "Dolmøy",
  7255: "Sundlandet",
  7256: "Hemnskjela",
  7257: "Snillfjord",
  7259: "Snillfjord",
  7260: "Sistranda",
  7261: "Sistranda",
  7263: "Hamarvik",
  7264: "Hamarvik",
  7266: "Kverva",
  7268: "Titran",
  7270: "Dyrvik",
  7273: "Norddyrøy",
  7280: "Sula",
  7282: "Bogøyvær",
  7284: "Mausund",
  7285: "Gjæsingen",
  7286: "Sørburøy",
  7287: "Sauøy",
  7288: "Soknedal",
  7289: "Soknedal",
  7290: "Støren",
  7291: "Støren",
  7295: "Rognes",
  7298: "Budalen",
  7300: "Orkanger",
  7301: "Orkanger",
  7302: "Orkanger",
  7310: "Gjølme",
  7315: "Lensvik",
  7316: "Lensvik",
  7318: "Agdenes",
  7319: "Agdenes",
  7320: "Fannrem",
  7321: "Fannrem",
  7327: "Svorkmo",
  7329: "Svorkmo",
  7331: "Løkken Verk",
  7332: "Løkken Verk",
  7333: "Storås",
  7334: "Storås",
  7335: "Jerpstad",
  7336: "Meldal",
  7338: "Meldal",
  7340: "Oppdal",
  7341: "Oppdal",
  7342: "Lønset",
  7343: "Vognill",
  7345: "Driva",
  7350: "Buvika",
  7351: "Buvika",
  7353: "Børsa",
  7354: "Viggja",
  7355: "Eggkleiva",
  7357: "Skaun",
  7358: "Børsa",
  7361: "Røros",
  7370: "Brekkebygd",
  7372: "Glåmos",
  7374: "Røros",
  7380: "Ålen",
  7383: "Haltdalen",
  7384: "Ålen",
  7386: "Singsås",
  7387: "Singsås",
  7391: "Rennebu",
  7392: "Rennebu",
  7393: "Rennebu",
  7397: "Rennebu",
  7398: "Rennebu",
  7399: "Rennebu",
  7400: "Trondheim",
  7401: "Trondheim",
  7402: "Trondheim",
  7403: "Trondheim",
  7404: "Trondheim",
  7405: "Trondheim",
  7406: "Trondheim",
  7407: "Trondheim",
  7408: "Trondheim",
  7409: "Trondheim",
  7410: "Trondheim",
  7411: "Trondheim",
  7412: "Trondheim",
  7413: "Trondheim",
  7414: "Trondheim",
  7415: "Trondheim",
  7416: "Trondheim",
  7417: "Trondheim",
  7418: "Trondheim",
  7419: "Trondheim",
  7420: "Trondheim",
  7421: "Trondheim",
  7422: "Trondheim",
  7424: "Trondheim",
  7425: "Trondheim",
  7426: "Trondheim",
  7427: "Trondheim",
  7428: "Trondheim",
  7429: "Trondheim",
  7430: "Trondheim",
  7431: "Trondheim",
  7432: "Trondheim",
  7433: "Trondheim",
  7434: "Trondheim",
  7435: "Trondheim",
  7436: "Trondheim",
  7437: "Trondheim",
  7438: "Trondheim",
  7439: "Trondheim",
  7440: "Trondheim",
  7441: "Trondheim",
  7442: "Trondheim",
  7443: "Trondheim",
  7444: "Trondheim",
  7445: "Trondheim",
  7446: "Trondheim",
  7447: "Trondheim",
  7448: "Trondheim",
  7449: "Trondheim",
  7450: "Trondheim",
  7451: "Trondheim",
  7452: "Trondheim",
  7453: "Trondheim",
  7454: "Trondheim",
  7455: "Trondheim",
  7456: "Trondheim",
  7457: "Trondheim",
  7458: "Trondheim",
  7459: "Trondheim",
  7461: "Trondheim",
  7462: "Trondheim",
  7463: "Trondheim",
  7465: "Trondheim",
  7466: "Trondheim",
  7467: "Trondheim",
  7468: "Trondheim",
  7469: "Trondheim",
  7470: "Trondheim",
  7471: "Trondheim",
  7472: "Trondheim",
  7473: "Trondheim",
  7474: "Trondheim",
  7475: "Trondheim",
  7476: "Trondheim",
  7477: "Trondheim",
  7478: "Trondheim",
  7479: "Trondheim",
  7480: "Trondheim",
  7481: "Trondheim",
  7482: "Trondheim",
  7483: "Trondheim",
  7484: "Trondheim",
  7485: "Trondheim",
  7486: "Trondheim",
  7487: "Trondheim",
  7488: "Trondheim",
  7489: "Trondheim",
  7490: "Trondheim",
  7491: "Trondheim",
  7492: "Trondheim",
  7493: "Trondheim",
  7494: "Trondheim",
  7495: "Trondheim",
  7496: "Trondheim",
  7497: "Trondheim",
  7500: "Stjørdal",
  7501: "Stjørdal",
  7502: "Stjørdal",
  7503: "Stjørdal",
  7504: "Stjørdal",
  7505: "Stjørdal",
  7506: "Stjørdal",
  7507: "Stjørdal",
  7508: "Stjørdal",
  7509: "Stjørdal",
  7510: "Skatval",
  7512: "Stjørdal",
  7513: "Stjørdal",
  7514: "Stjørdal",
  7517: "Hell",
  7519: "Elvarli",
  7520: "Hegra",
  7525: "Flornes",
  7529: "Hegra",
  7530: "Meråker",
  7531: "Meråker",
  7533: "Kopperå",
  7540: "Klæbu",
  7541: "Klæbu",
  7549: "Tanem",
  7550: "Hommelvik",
  7551: "Hommelvik",
  7560: "Vikhammer",
  7562: "Saksvik",
  7563: "Malvik",
  7566: "Vikhammer",
  7570: "Hell",
  7580: "Selbu",
  7581: "Selbu",
  7583: "Selbu",
  7584: "Selbustrand",
  7590: "Tydal",
  7591: "Tydal",
  7596: "Flaknan",
  7600: "Levanger",
  7601: "Levanger",
  7602: "Levanger",
  7603: "Levanger",
  7604: "Levanger",
  7605: "Levanger",
  7606: "Levanger",
  7607: "Levanger",
  7608: "Levanger",
  7609: "Levanger",
  7610: "Levanger",
  7619: "Skogn",
  7620: "Skogn",
  7622: "Markabygda",
  7623: "Ronglan",
  7624: "Ekne",
  7629: "Ytterøy",
  7630: "Åsen",
  7631: "Åsen",
  7632: "Åsenfjord",
  7633: "Frosta",
  7634: "Frosta",
  7650: "Verdal",
  7651: "Verdal",
  7652: "Verdal",
  7653: "Verdal",
  7654: "Verdal",
  7655: "Verdal",
  7656: "Verdal",
  7657: "Verdal",
  7658: "Verdal",
  7660: "Vuku",
  7670: "Inderøy",
  7671: "Inderøy",
  7672: "Inderøy",
  7690: "Mosvik",
  7701: "Steinkjer",
  7702: "Steinkjer",
  7703: "Steinkjer",
  7704: "Steinkjer",
  7705: "Steinkjer",
  7707: "Steinkjer",
  7708: "Steinkjer",
  7709: "Steinkjer",
  7710: "Sparbu",
  7711: "Steinkjer",
  7712: "Steinkjer",
  7713: "Steinkjer",
  7714: "Steinkjer",
  7715: "Steinkjer",
  7716: "Steinkjer",
  7717: "Steinkjer",
  7718: "Steinkjer",
  7724: "Steinkjer",
  7725: "Steinkjer",
  7726: "Steinkjer",
  7729: "Steinkjer",
  7730: "Beitstad",
  7732: "Steinkjer",
  7734: "Steinkjer",
  7735: "Steinkjer",
  7736: "Steinkjer",
  7737: "Steinkjer",
  7738: "Steinkjer",
  7739: "Beitstad",
  7740: "Steinsdalen",
  7741: "Steinsdalen",
  7742: "Yttervåg",
  7744: "Hepsøy",
  7745: "Oppland",
  7746: "Hasvåg",
  7748: "Sætervik",
  7750: "Namdalseid",
  7751: "Namdalseid",
  7760: "Snåsa",
  7761: "Snåsa",
  7770: "Flatanger",
  7771: "Flatanger",
  7777: "Nord-Statland",
  7790: "Malm",
  7791: "Malm",
  7796: "Follafoss",
  7797: "Verrabotn",
  7800: "Namsos",
  7801: "Namsos",
  7802: "Namsos",
  7803: "Namsos",
  7804: "Namsos",
  7805: "Namsos",
  7808: "Namsos",
  7810: "Namsos",
  7817: "Salsnes",
  7818: "Lund",
  7819: "Fosslandsosen",
  7820: "Spillum",
  7821: "Spillum",
  7822: "Bangsund",
  7856: "Jøa",
  7860: "Skage I Namdalen",
  7863: "Overhalla",
  7864: "Overhalla",
  7869: "Skage I Namdalen",
  7870: "Grong",
  7871: "Grong",
  7873: "Harran",
  7882: "Nordli",
  7884: "Sørli",
  7890: "Namsskogan",
  7892: "Trones",
  7893: "Skorovatn",
  7896: "Brekkvasselv",
  7898: "Limingen",
  7900: "Rørvik",
  7901: "Rørvik",
  7940: "Ottersøy",
  7944: "Indre Nærøy",
  7950: "Abelvær",
  7960: "Salsbruket",
  7970: "Kolvereid",
  7971: "Kolvereid",
  7973: "Gjerdinga",
  7976: "Kongsmoen",
  7977: "Høylandet",
  7980: "Terråk",
  7981: "Harangsfjord",
  7982: "Bindalseidet",
  7985: "Foldereid",
  7990: "Naustbukta",
  7993: "Gutvik",
  7994: "Leka",
  8001: "Bodø",
  8002: "Bodø",
  8003: "Bodø",
  8004: "Bodø",
  8005: "Bodø",
  8006: "Bodø",
  8007: "Bodø",
  8008: "Bodø",
  8009: "Bodø",
  8010: "Bodø",
  8011: "Bodø",
  8012: "Bodø",
  8013: "Bodø",
  8014: "Bodø",
  8015: "Bodø",
  8016: "Bodø",
  8019: "Bodø",
  8020: "Bodø",
  8021: "Bodø",
  8022: "Bodø",
  8023: "Bodø",
  8026: "Bodø",
  8027: "Bodø",
  8028: "Bodø",
  8029: "Bodø",
  8030: "Bodø",
  8031: "Bodø",
  8037: "Bodø",
  8038: "Bodø",
  8041: "Bodø",
  8047: "Bodø",
  8048: "Bodø",
  8049: "Bodø",
  8050: "Tverlandet",
  8056: "Saltstraumen",
  8058: "Tverlandet",
  8063: "Værøy",
  8064: "Røst",
  8070: "Bodø",
  8071: "Bodø",
  8072: "Bodø",
  8073: "Bodø",
  8074: "Bodø",
  8075: "Bodø",
  8076: "Bodø",
  8079: "Bodø",
  8084: "Bodø",
  8086: "Bodø",
  8087: "Bodø",
  8088: "Bodø",
  8089: "Bodø",
  8091: "Bodø",
  8092: "Bodø",
  8093: "Kjerringøy",
  8094: "Fleinvær",
  8095: "Helligvær",
  8096: "Bliksvær",
  8097: "Givær",
  8098: "Landegode",
  8099: "Jan Mayen",
  8100: "Misvær",
  8102: "Skjerstad",
  8103: "Breivik I Salten",
  8108: "Misvær",
  8110: "Moldjord",
  8114: "Tollå",
  8118: "Moldjord",
  8120: "Nygårdsjøen",
  8128: "Ytre Beiarn",
  8130: "Sandhornøy",
  8135: "Sørarnøy",
  8136: "Nordarnøy",
  8138: "Inndyr",
  8140: "Inndyr",
  8145: "Storvik",
  8146: "Reipå",
  8149: "Neverdal",
  8150: "Ørnes",
  8151: "Ørnes",
  8157: "Meløy",
  8158: "Bolga",
  8159: "Støtt",
  8160: "Glomfjord",
  8161: "Glomfjord",
  8168: "Engavågen",
  8170: "Engavågen",
  8178: "Halsa",
  8181: "Myken",
  8182: "Melfjordbotn",
  8184: "Ågskardet",
  8185: "Vågaholmen",
  8186: "Tjongsfjorden",
  8187: "Jektvik",
  8188: "Nordvernes",
  8189: "Gjersvikgrenda",
  8190: "Sørfjorden",
  8193: "Rødøy",
  8195: "Gjerøy",
  8196: "Selsøyvik",
  8197: "Storselsøy",
  8198: "Nordnesøy",
  8200: "Fauske",
  8201: "Fauske",
  8202: "Fauske",
  8203: "Fauske",
  8205: "Fauske",
  8206: "Fauske",
  8207: "Fauske",
  8208: "Fauske",
  8209: "Fauske",
  8210: "Fauske",
  8211: "Fauske",
  8214: "Fauske",
  8215: "Valnesfjord",
  8218: "Fauske",
  8219: "Fauske",
  8220: "Røsvik",
  8226: "Straumen",
  8230: "Sulitjelma",
  8231: "Sulitjelma",
  8232: "Straumen",
  8233: "Valnesfjord",
  8250: "Rognan",
  8251: "Rognan",
  8255: "Røkland",
  8256: "Røkland",
  8260: "Innhavet",
  8261: "Innhavet",
  8264: "Engan",
  8266: "Mørsvikbotn",
  8270: "Drag",
  8271: "Drag",
  8273: "Nevervik",
  8274: "Musken",
  8275: "Storjord I Tysfjord",
  8276: "Ulvsvåg",
  8278: "Storå",
  8281: "Leinesfjord",
  8283: "Leinesfjord",
  8285: "Leines",
  8286: "Nordfold",
  8288: "Bogøy",
  8289: "Engeløya",
  8290: "Skutvik",
  8294: "Hamarøy",
  8297: "Tranøy",
  8298: "Hamarøy",
  8300: "Svolvær",
  8301: "Svolvær",
  8305: "Svolvær",
  8309: "Kabelvåg",
  8310: "Kabelvåg",
  8311: "Henningsvær",
  8312: "Henningsvær",
  8313: "Kleppstad",
  8314: "Gimsøysand",
  8315: "Laukvik",
  8316: "Laupstad",
  8317: "Strønstad",
  8320: "Skrova",
  8322: "Brettesnes",
  8323: "Storfjell",
  8324: "Digermulen",
  8325: "Tengelfjord",
  8326: "Myrland",
  8328: "Storemolla",
  8340: "Stamsund",
  8352: "Sennesvik",
  8357: "Valberg",
  8360: "Bøstad",
  8370: "Leknes",
  8372: "Gravdal",
  8373: "Ballstad",
  8376: "Leknes",
  8377: "Gravdal",
  8378: "Stamsund",
  8380: "Ramberg",
  8382: "Napp",
  8384: "Sund I Lofoten",
  8387: "Fredvang",
  8388: "Ramberg",
  8390: "Reine",
  8392: "Sørvågen",
  8393: "Sørvågen",
  8398: "Reine",
  8400: "Sortland",
  8401: "Sortland",
  8402: "Sortland",
  8403: "Sortland",
  8404: "Sortland",
  8405: "Sortland",
  8406: "Sortland",
  8407: "Sortland",
  8408: "Sortland",
  8409: "Gullesfjord",
  8410: "Lødingen",
  8411: "Lødingen",
  8412: "Vestbygd",
  8413: "Kvitnes",
  8414: "Hennes",
  8415: "Sortland",
  8416: "Sortland",
  8426: "Barkestad",
  8428: "Tunstad",
  8430: "Myre",
  8432: "Alsvåg",
  8438: "Stø",
  8439: "Myre",
  8445: "Melbu",
  8447: "Lonkan",
  8450: "Stokmarknes",
  8455: "Stokmarknes",
  8459: "Melbu",
  8465: "Straumsjøen",
  8469: "Bø I Vesterålen",
  8470: "Bø I Vesterålen",
  8475: "Straumsjøen",
  8480: "Andenes",
  8481: "Bleik",
  8483: "Andenes",
  8484: "Risøyhamn",
  8485: "Dverberg",
  8488: "Nøss",
  8489: "Nordmela",
  8493: "Risøyhamn",
  8501: "Narvik",
  8502: "Narvik",
  8503: "Narvik",
  8504: "Narvik",
  8505: "Narvik",
  8506: "Narvik",
  8507: "Narvik",
  8508: "Narvik",
  8509: "Narvik",
  8510: "Narvik",
  8512: "Narvik",
  8513: "Ankenes",
  8514: "Narvik",
  8515: "Narvik",
  8516: "Narvik",
  8517: "Narvik",
  8518: "Narvik",
  8520: "Ankenes",
  8522: "Beisfjord",
  8523: "Elvegård",
  8530: "Bjerkvik",
  8531: "Bjerkvik",
  8533: "Bogen I Ofoten",
  8534: "Liland",
  8535: "Tårstad",
  8536: "Evenes",
  8539: "Bogen I Ofoten",
  8540: "Ballangen",
  8543: "Kjeldebotn",
  8546: "Ballangen",
  8590: "Kjøpsvik",
  8591: "Kjøpsvik",
  8601: "Mo I Rana",
  8602: "Mo I Rana",
  8603: "Mo I Rana",
  8604: "Mo I Rana",
  8607: "Mo I Rana",
  8608: "Mo I Rana",
  8610: "Mo I Rana",
  8613: "Mo I Rana",
  8614: "Mo I Rana",
  8615: "Skonseng",
  8616: "Mo I Rana",
  8617: "Dalsgrenda",
  8618: "Mo I Rana",
  8622: "Mo I Rana",
  8624: "Mo I Rana",
  8626: "Mo I Rana",
  8630: "Storforshei",
  8634: "Mo I Rana",
  8638: "Storforshei",
  8640: "Hemnesberget",
  8641: "Hemnesberget",
  8642: "Finneidfjord",
  8643: "Bjerka",
  8646: "Korgen",
  8647: "Bleikvasslia",
  8648: "Korgen",
  8651: "Mosjøen",
  8652: "Mosjøen",
  8654: "Mosjøen",
  8655: "Mosjøen",
  8656: "Mosjøen",
  8657: "Mosjøen",
  8658: "Mosjøen",
  8659: "Mosjøen",
  8660: "Mosjøen",
  8661: "Mosjøen",
  8663: "Mosjøen",
  8664: "Mosjøen",
  8665: "Mosjøen",
  8666: "Mosjøen",
  8672: "Elsfjord",
  8680: "Trofors",
  8681: "Trofors",
  8690: "Hattfjelldal",
  8691: "Hattfjelldal",
  8700: "Nesna",
  8701: "Nesna",
  8720: "Vikholmen",
  8723: "Husby",
  8724: "Saura",
  8725: "Utskarpen",
  8730: "Bratland",
  8732: "Aldra",
  8733: "Stuvland",
  8735: "Stokkvågen",
  8740: "Nord-Solvær",
  8742: "Selvær",
  8743: "Indre Kvarøy",
  8750: "Tonnes",
  8752: "Konsvikosen",
  8753: "Konsvikosen",
  8754: "Øresvik",
  8762: "Sleneset",
  8764: "Lovund",
  8766: "Lurøy",
  8767: "Lurøy",
  8770: "Træna",
  8800: "Sandnessjøen",
  8801: "Sandnessjøen",
  8802: "Sandnessjøen",
  8803: "Sandnessjøen",
  8804: "Sandnessjøen",
  8805: "Sandnessjøen",
  8809: "Sandnessjøen",
  8813: "Løkta",
  8820: "Dønna",
  8827: "Dønna",
  8830: "Vandve",
  8842: "Brasøy",
  8844: "Sandvær",
  8850: "Herøy",
  8851: "Herøy",
  8852: "Herøy",
  8854: "Austbø",
  8860: "Tjøtta",
  8861: "Tjøtta",
  8865: "Tro",
  8870: "Visthus",
  8880: "Bærøyvågen",
  8890: "Leirfjord",
  8891: "Leirfjord",
  8892: "Sundøy",
  8897: "Bardal",
  8900: "Brønnøysund",
  8901: "Brønnøysund",
  8902: "Brønnøysund",
  8904: "Brønnøysund",
  8905: "Brønnøysund",
  8906: "Brønnøysund",
  8907: "Brønnøysund",
  8908: "Brønnøysund",
  8909: "Brønnøysund",
  8910: "Brønnøysund",
  8920: "Sømna",
  8921: "Sømna",
  8922: "Sømna",
  8960: "Velfjord",
  8961: "Velfjord",
  8976: "Vevelstad",
  8980: "Vega",
  8981: "Vega",
  8985: "Ylvingen",
  9006: "Tromsø",
  9007: "Tromsø",
  9008: "Tromsø",
  9009: "Tromsø",
  9010: "Tromsø",
  9011: "Tromsø",
  9012: "Tromsø",
  9013: "Tromsø",
  9014: "Tromsø",
  9015: "Tromsø",
  9016: "Tromsø",
  9017: "Tromsø",
  9018: "Tromsø",
  9019: "Tromsø",
  9020: "Tromsdalen",
  9021: "Tromsdalen",
  9022: "Krokelvdalen",
  9023: "Krokelvdalen",
  9024: "Tomasjord",
  9027: "Ramfjordbotn",
  9029: "Tromsdalen",
  9030: "Sjursnes",
  9034: "Oldervik",
  9037: "Tromsø",
  9038: "Tromsø",
  9040: "Nordkjosbotn",
  9042: "Laksvatn",
  9043: "Jøvik",
  9046: "Oteren",
  9049: "Nordkjosbotn",
  9050: "Storsteinnes",
  9055: "Meistervik",
  9056: "Mortenhals",
  9057: "Vikran",
  9059: "Storsteinnes",
  9060: "Lyngseidet",
  9062: "Furuflaten",
  9064: "Svensby",
  9068: "Nord-Lenangen",
  9069: "Lyngseidet",
  9100: "Kvaløysletta",
  9101: "Kvaløysletta",
  9102: "Kvaløysletta",
  9103: "Kvaløya",
  9104: "Kvaløya",
  9105: "Kvaløya",
  9106: "Straumsbukta",
  9107: "Kvaløya",
  9108: "Kvaløya",
  9110: "Sommarøy",
  9118: "Brensholmen",
  9119: "Sommarøy",
  9120: "Vengsøy",
  9128: "Tussøy",
  9130: "Hansnes",
  9131: "Kårvik",
  9132: "Stakkvik",
  9134: "Hansnes",
  9135: "Vannvåg",
  9136: "Vannareid",
  9137: "Vannvåg",
  9138: "Karlsøy",
  9140: "Rebbenes",
  9141: "Mjølvik",
  9142: "Skibotn",
  9143: "Skibotn",
  9144: "Samuelsberg",
  9146: "Olderdalen",
  9147: "Birtavarre",
  9148: "Olderdalen",
  9151: "Storslett",
  9152: "Sørkjosen",
  9153: "Rotsund",
  9156: "Storslett",
  9159: "Havnnes",
  9161: "Burfjord",
  9162: "Sørstraumen",
  9163: "Jøkelfjord",
  9169: "Burfjord",
  9170: "Longyearbyen",
  9171: "Longyearbyen",
  9173: "Ny-Ålesund",
  9174: "Hopen",
  9175: "Sveagruva",
  9176: "Bjørnøya",
  9178: "Barentsburg",
  9180: "Skjervøy",
  9181: "Hamneidet",
  9182: "Seglvik",
  9184: "Reinfjord",
  9185: "Spildra",
  9186: "Andsnes",
  9187: "Valanhamn",
  9189: "Skjervøy",
  9190: "Akkarvik",
  9192: "Arnøyhamn",
  9193: "Nikkeby",
  9194: "Lauksletta",
  9195: "Årviksand",
  9197: "Uløybukt",
  9240: "Tromsø",
  9251: "Tromsø",
  9252: "Tromsø",
  9253: "Tromsø",
  9254: "Tromsø",
  9255: "Tromsø",
  9256: "Tromsø",
  9257: "Tromsø",
  9258: "Tromsø",
  9259: "Tromsø",
  9260: "Tromsø",
  9261: "Tromsø",
  9262: "Tromsø",
  9263: "Tromsø",
  9265: "Tromsø",
  9266: "Tromsø",
  9267: "Tromsø",
  9268: "Tromsø",
  9269: "Tromsø",
  9270: "Tromsø",
  9271: "Tromsø",
  9272: "Tromsø",
  9273: "Tromsø",
  9274: "Tromsø",
  9275: "Tromsø",
  9276: "Tromsø",
  9277: "Tromsø",
  9278: "Tromsø",
  9279: "Tromsø",
  9280: "Tromsø",
  9281: "Tromsø",
  9282: "Tromsø",
  9283: "Tromsø",
  9284: "Tromsø",
  9285: "Tromsø",
  9286: "Tromsø",
  9287: "Tromsø",
  9288: "Tromsø",
  9290: "Tromsø",
  9291: "Tromsø",
  9292: "Tromsø",
  9293: "Tromsø",
  9294: "Tromsø",
  9296: "Tromsø",
  9298: "Tromsø",
  9299: "Tromsø",
  9300: "Finnsnes",
  9302: "Rossfjordstraumen",
  9303: "Silsand",
  9304: "Vangsvik",
  9305: "Finnsnes",
  9306: "Finnsnes",
  9307: "Finnsnes",
  9308: "Finnsnes",
  9309: "Finnsnes",
  9310: "Sørreisa",
  9311: "Brøstadbotn",
  9315: "Sørreisa",
  9316: "Brøstadbotn",
  9321: "Moen",
  9322: "Karlstad",
  9325: "Bardufoss",
  9326: "Bardufoss",
  9329: "Moen",
  9334: "Øverbygd",
  9335: "Øverbygd",
  9336: "Rundhaug",
  9350: "Sjøvegan",
  9355: "Sjøvegan",
  9357: "Tennevoll",
  9358: "Tennevoll",
  9360: "Bardu",
  9365: "Bardu",
  9370: "Silsand",
  9372: "Gibostad",
  9373: "Botnhamn",
  9376: "Skatvik",
  9379: "Gryllefjord",
  9380: "Gryllefjord",
  9381: "Torsken",
  9382: "Gibostad",
  9384: "Skaland",
  9385: "Skaland",
  9386: "Senjahopen",
  9387: "Senjahopen",
  9388: "Fjordgard",
  9389: "Husøy I Senja",
  9391: "Stonglandseidet",
  9392: "Stonglandseidet",
  9393: "Flakstadvåg",
  9395: "Kaldfarnes",
  9402: "Harstad",
  9403: "Harstad",
  9404: "Harstad",
  9405: "Harstad",
  9406: "Harstad",
  9407: "Harstad",
  9408: "Harstad",
  9409: "Harstad",
  9411: "Harstad",
  9414: "Harstad",
  9415: "Harstad",
  9419: "Sørvik",
  9420: "Lundenes",
  9423: "Grøtavær",
  9424: "Kjøtta",
  9425: "Sandsøy",
  9426: "Bjarkøy",
  9427: "Meløyvær",
  9430: "Sandtorg",
  9436: "Kongsvik",
  9439: "Evenskjer",
  9440: "Evenskjer",
  9441: "Fjelldal",
  9442: "Ramsund",
  9443: "Myklebostad",
  9444: "Hol I Tjeldsund",
  9445: "Tovik",
  9446: "Grovfjord",
  9447: "Grovfjord",
  9448: "Ramsund",
  9450: "Hamnvik",
  9451: "Hamnvik",
  9453: "Kråkrøhamn",
  9454: "Ånstad",
  9455: "Engenes",
  9470: "Gratangen",
  9471: "Gratangen",
  9475: "Borkenes",
  9476: "Borkenes",
  9479: "Harstad",
  9480: "Harstad",
  9481: "Harstad",
  9482: "Harstad",
  9483: "Harstad",
  9484: "Harstad",
  9485: "Harstad",
  9486: "Harstad",
  9487: "Harstad",
  9488: "Harstad",
  9489: "Harstad",
  9496: "Harstad",
  9497: "Harstad",
  9498: "Harstad",
  9501: "Alta",
  9502: "Alta",
  9503: "Alta",
  9504: "Alta",
  9505: "Alta",
  9506: "Alta",
  9507: "Alta",
  9508: "Alta",
  9509: "Alta",
  9510: "Alta",
  9511: "Alta",
  9512: "Alta",
  9513: "Alta",
  9514: "Alta",
  9515: "Alta",
  9516: "Alta",
  9517: "Alta",
  9518: "Alta",
  9519: "Kviby",
  9520: "Kautokeino",
  9521: "Kautokeino",
  9525: "Maze",
  9531: "Kvalfjord",
  9532: "Hakkstabben",
  9533: "Kongshus",
  9536: "Korsfjorden",
  9540: "Talvik",
  9545: "Langfjordbotn",
  9550: "Øksfjord",
  9580: "Bergsfjord",
  9582: "Nuvsvåg",
  9583: "Langfjordhamn",
  9584: "Sør-Tverrfjord",
  9585: "Sandland",
  9586: "Loppa",
  9587: "Skavnakk",
  9590: "Hasvik",
  9593: "Breivikbotn",
  9595: "Sørvær",
  9600: "Hammerfest",
  9601: "Hammerfest",
  9602: "Hammerfest",
  9603: "Hammerfest",
  9609: "Nordre Seiland",
  9610: "Rypefjord",
  9612: "Forsøl",
  9615: "Hammerfest",
  9616: "Hammerfest",
  9620: "Kvalsund",
  9621: "Kvalsund",
  9624: "Revsneshamn",
  9650: "Akkarfjord",
  9651: "Langstrand",
  9657: "Kårhamn",
  9664: "Sandøybotn",
  9670: "Tufjord",
  9672: "Ingøy",
  9690: "Havøysund",
  9691: "Havøysund",
  9692: "Måsøy",
  9700: "Lakselv",
  9709: "Porsangmoen",
  9710: "Indre Billefjord",
  9711: "Lakselv",
  9712: "Lakselv",
  9713: "Russenes",
  9714: "Snefjord",
  9715: "Kokelv",
  9716: "Børselv",
  9717: "Veidnesklubben",
  9722: "Skoganvarre",
  9730: "Karasjok",
  9735: "Karasjok",
  9740: "Lebesby",
  9742: "Kunes",
  9750: "Honningsvåg",
  9751: "Honningsvåg",
  9760: "Nordvågen",
  9763: "Skarsvåg",
  9764: "Nordkapp",
  9765: "Gjesvær",
  9768: "Repvåg",
  9770: "Mehamn",
  9771: "Skjånes",
  9772: "Langfjordnes",
  9773: "Nervei",
  9775: "Gamvik",
  9782: "Dyfjord",
  9790: "Kjøllefjord",
  9800: "Vadsø",
  9802: "Vestre Jakobselv",
  9810: "Vestre Jakobselv",
  9811: "Vadsø",
  9815: "Vadsø",
  9820: "Varangerbotn",
  9826: "Sirma",
  9840: "Varangerbotn",
  9845: "Tana",
  9846: "Tana",
  9900: "Kirkenes",
  9910: "Bjørnevatn",
  9912: "Hesseng",
  9914: "Bjørnevatn",
  9915: "Kirkenes",
  9916: "Hesseng",
  9917: "Kirkenes",
  9925: "Svanvik",
  9930: "Neiden",
  9935: "Bugøynes",
  9950: "Vardø",
  9951: "Vardø",
  9960: "Kiberg",
  9980: "Berlevåg",
  9981: "Berlevåg",
  9982: "Kongsfjord",
  9990: "Båtsfjord",
  9991: "Båtsfjord"
};

export default postalCodes;

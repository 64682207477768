// Entry point for the build script in your package.json

// Rails
import Rails from "@rails/ujs";
Rails.start();

// Stimulus
import { Application } from "@hotwired/stimulus";
import AddressController from "./controllers/AddressController";
import HeaderController from "./controllers/HeaderController";
import ItemPhotosController from "./controllers/ItemPhotosController";
import PhotoUploadsController from "./controllers/PhotoUploadsController";
import SortableLotsController from "./controllers/SortableLotsController";
const application = Application.start();
application.register("address", AddressController);
application.register("header", HeaderController);
application.register("item-photos", ItemPhotosController);
application.register("photo-uploads", PhotoUploadsController);
application.register("sortable-lots", SortableLotsController);

// Features
import "./features/bidForm";
import "./features/disabledLinks";
import "./features/itemCollection";
import "./features/itemFolders";
import "./features/itemSalesContracts";
import "./features/richText";
import "./features/select";
import "./features/selectableList";

// Sentry
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://6d2aae4d3bc746e8a75e3d0924322dd3:2cf293e7941d48e780513274d92fb90c@sentry.io/209817"
});

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

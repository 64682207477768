import React from "react";
import BlockTypeButton from "./BlockTypeButton";
import InlineStyleButton from "./InlineStyleButton";

interface Props {
  onChange: (state: Draft.EditorState) => void;
  editorState: Draft.EditorState;
}

export default function Buttons(props: Props) {
  const { editorState, onChange } = props;
  return (
    <div className="actions">
      <InlineStyleButton
        label="B"
        className="bold"
        title="Bold"
        style="BOLD"
        editorState={editorState}
        onChange={onChange}
      />
      <InlineStyleButton
        label="I"
        className="italic"
        title="Italic"
        style="ITALIC"
        editorState={editorState}
        onChange={onChange}
      />
      <InlineStyleButton
        label="U"
        className="underline"
        title="Underline"
        style="UNDERLINE"
        editorState={editorState}
        onChange={onChange}
      />
      <BlockTypeButton
        label="H"
        className="h2"
        title="Heading"
        type="header-two"
        editorState={editorState}
        onChange={onChange}
      />
      <BlockTypeButton
        label="BQ"
        className="blockquote"
        title="Block quote"
        type="blockquote"
        editorState={editorState}
        onChange={onChange}
      />
    </div>
  );
}

import $ from "jquery";
import loadSelect2 from "select2";
(loadSelect2 as () => void)();

interface Artist {
  name: string;
}

interface Item {
  id: number;
  title: string;
  artist?: Artist;
}

interface ItemsResponse {
  items: Item[];
}

interface Customer {
  id: number;
  name: string;
}

interface CustomersResponse {
  customers: Customer[];
}

interface SalesContract {
  id: number;
  name: string;
}

interface SalesContractsResponse {
  sales_contracts: SalesContract[];
}

$(() => {
  $("select").select2();

  $(".item-select select").select2({
    placeholder: "Velg objekt",
    ajax: {
      url: "/items/search.json",
      delay: 250,
      data: function (params) {
        return { q: params.term };
      },
      processResults: function (data: ItemsResponse) {
        return {
          results: data.items.map(function (i) {
            if (i.artist) {
              return {
                id: i.id,
                text: `${i.id} - ${i.artist.name} - ${i.title}`
              };
            } else {
              return {
                id: i.id,
                text: `${i.id} - ${i.title}`
              };
            }
          })
        };
      }
    }
  });

  $(".customer-select select").select2({
    placeholder: "Velg kunde",
    ajax: {
      url: "/customers/search.json",
      delay: 250,
      data: function (params) {
        return { q: params.term };
      },
      processResults: function (data: CustomersResponse) {
        return {
          results: data.customers.map(function (c) {
            return { id: c.id, text: c.name };
          })
        };
      }
    }
  });

  $(".sales-contract-select select").select2({
    placeholder: "Velg kontrakt",
    ajax: {
      url: "/sales_contracts/search.json",
      delay: 250,
      data: function (params) {
        return { q: params.term, open: true };
      },
      processResults: function (data: SalesContractsResponse) {
        return {
          results: data.sales_contracts.map(function (c) {
            return { id: c.id, text: c.name };
          })
        };
      }
    }
  });
});

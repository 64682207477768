import { Controller } from "@hotwired/stimulus";
import postalCodes from "../lib/postalCodes";

export default class extends Controller {
  countryTarget: HTMLInputElement;
  postalAreaTarget: HTMLInputElement;

  static get targets() {
    return ["country", "postalArea"];
  }

  changePostalCode(evt: Event) {
    const code = (evt.target as HTMLInputElement).value;
    if (this.countryTarget.value == "Norge" && code && code in postalCodes) {
      this.postalAreaTarget.value = postalCodes[code];
    }
  }
}

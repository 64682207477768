import React, { useRef, useState } from "react";
import { Editor, RichUtils } from "draft-js";
import Buttons from "./RichEditor/Buttons";

import "draft-js/dist/Draft.css";

interface Props {
  editorState: Draft.EditorState;
  placeholder: string;
  onChange?: (state: Draft.EditorState) => void;
}

export default function RichEditor(props: Props) {
  const { editorState, placeholder } = props;

  const [focus, setFocus] = useState(false);
  const editorRef = useRef();

  const onChange = (editorState: Draft.EditorState) => {
    if (props.onChange) {
      props.onChange(editorState);
    }
  };

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  const handleKeyCommand = (
    command: string,
    editorState: Draft.EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleReturn = (e: React.KeyboardEvent) => {
    if (e.shiftKey) {
      onChange(RichUtils.insertSoftNewline(editorState));
      return "handled";
    }
    return "not-handled";
  };

  return (
    <div className="rich-editor">
      {focus && <Buttons editorState={editorState} onChange={onChange} />}
      <div className="content">
        <Editor
          ref={editorRef}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          handleReturn={handleReturn}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

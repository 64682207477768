import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(evt: Event) {
    if (evt.target == this.element) {
      evt.preventDefault();
      this.element.classList.toggle("expanded");
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import jQuery from "jquery";

import sortable from "html5sortable/dist/html5sortable.cjs";

declare function sortable(
  el: HTMLElement,
  opts: Record<string, unknown>
): HTMLElement[];

export default class extends Controller {
  bodyTarget: HTMLTableElement;

  static get targets() {
    return ["body"];
  }

  initialize() {
    const table = sortable(this.bodyTarget, {
      handle: ".drag-handle",
      items: "tr",
      placeholder: '<tr class="placeholder"><td colspan="8" /></tr>'
    })[0];

    table.addEventListener("sortupdate", (e: SortEvent) => {
      const table = this.element as HTMLTableElement;
      const ids = e.detail.destination.items.map((p) => p.dataset.lotId);

      e.detail.item.classList.add("dropped");

      if ("dataset" in table) {
        void jQuery.post(table.dataset.reorderPath, {
          lot_ids: ids,
          authenticity_token: table.dataset.authToken,
          success: () => {
            setTimeout(() => {
              e.detail.item.classList.remove("dropped");
            }, 20);
          }
        });
      }
    });
  }
}

import React from "react";
import { RichUtils } from "draft-js";

interface Props {
  onChange: (state: Draft.EditorState) => void;
  editorState: Draft.EditorState;
  label: string;
  title: string;
  className: string;
  type: string;
}

export default function BlockTypeButton(props: Props) {
  const { onChange, editorState, label, title, className, type } = props;

  const preventClick = (evt: React.MouseEvent) => evt.preventDefault();

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    onChange(RichUtils.toggleBlockType(editorState, type));
  };

  const currentType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType();

  const classNames = [className];
  if (type === currentType) {
    classNames.push("active");
  }

  return (
    <button
      className={classNames.join(" ")}
      title={title}
      onMouseDown={handleClick}
      onClick={preventClick}>
      {label}
    </button>
  );
}

import { Controller } from "@hotwired/stimulus";
import jQuery from "jquery";

import sortable from "html5sortable/dist/html5sortable.cjs";

declare function sortable(
  el: HTMLElement,
  opts: Record<string, unknown>
): HTMLElement[];

export default class extends Controller {
  initialize() {
    const photos = sortable(this.element as HTMLElement, {
      items: ".photo",
      placeholder: '<div class="placeholder photo" />'
    })[0];

    photos.addEventListener("sortupdate", (e: SortEvent) => {
      const elem = this.element as HTMLElement;

      const ids = e.detail.destination.items.map((p) => p.dataset.photoId);
      if ("dataset" in elem) {
        void jQuery.post(elem.dataset.reorderPath, {
          photo_ids: ids,
          authenticity_token: elem.dataset.authToken
        });
      }
    });
  }
}

import $ from "jquery";

type MinPrices = Record<string, number>;
type Thumbnails = Record<string, string>;

$(function () {
  $(".bid-form").each(function () {
    const minPrices: MinPrices = $(this).data("minimum-prices") as MinPrices;
    const thumbnails = $(this).data("thumbnails") as Thumbnails;

    const updateThumbnail = () => {
      const id = $(this).find("#bid_lot_id option:selected").val() as string;
      const src = thumbnails[id];
      if (src) {
        $(this)
          .find(".thumbnail")
          .html('<img src="' + src + '" />');
      } else {
        $(this).find(".thumbnail").html("");
      }
    };

    const updateReservation = () => {
      const id = $(this).find("#bid_lot_id option:selected").val() as string;
      const bid = $(this).find(".bid").val() as string;

      if (bid == "") {
        $(this).find(".sold-reservation").prop("checked", false);
      } else {
        $(this)
          .find(".sold-reservation")
          .prop("checked", parseInt(bid) < minPrices[id]);
      }
    };

    $(this).find(".bid").on("change", updateReservation);
    $(this).find(".bid").on("keyup", updateReservation);
    $(this).find("#bid_lot_id").on("change", updateReservation);

    $(this).find("#bid_lot_id").on("change", updateThumbnail);
    updateThumbnail();
  });
});

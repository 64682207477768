import jQuery from "jquery";

type Decoration = [string, string, string];
type Decorator = (s: string) => Decoration;

class TextileDecorator {
  blockquote(str: string): Decoration {
    return ["bq. ", str, ""];
  }
  bold(str: string): Decoration {
    return ["<b>", str, "</b>"];
  }
  emphasis(str: string): Decoration {
    return ["<i>", str, "</i>"];
  }
  h1(str: string): Decoration {
    return ["h1. ", str, ""];
  }
  h2(str: string): Decoration {
    return ["h2. ", str, ""];
  }
  h3(str: string): Decoration {
    return ["h3. ", str, ""];
  }
  h4(str: string): Decoration {
    return ["h4. ", str, ""];
  }
  h5(str: string): Decoration {
    return ["h5. ", str, ""];
  }
  h6(str: string): Decoration {
    return ["h6. ", str, ""];
  }
  link(url: string, name: string): Decoration {
    return ['"', name, `":${url}`];
  }
  email(address: string, name: string): Decoration {
    return ['"', name, `":mailto:${address}`];
  }

  list(str: string) {
    return [
      "",
      str
        .split("\n")
        .map((l) => "* " + l)
        .join("\n"),
      ""
    ];
  }

  orderedList(str: string) {
    return [
      "",
      str
        .split("\n")
        .map((l) => "# " + l)
        .join("\n"),
      ""
    ];
  }
}

class RichTextArea {
  textarea: HTMLTextAreaElement;
  decorator: TextileDecorator;
  toolbar: JQuery;

  constructor(textarea: HTMLTextAreaElement) {
    this.textarea = textarea;
    this.decorator = new TextileDecorator();
    this.toolbar = jQuery('<ul class="rich-text-toolbar"></ul>').insertBefore(
      this.textarea
    );
    this.addButtons();
  }

  getSelection() {
    const { selectionStart, selectionEnd, value } = this.textarea;
    return value.substr(selectionStart, selectionEnd - selectionStart);
  }

  getSelectionRange() {
    if (typeof this.textarea.selectionStart !== "undefined") {
      return [this.textarea.selectionStart, this.textarea.selectionEnd];
    } else {
      return [0, 0];
    }
  }

  replaceSelection(prefix: string, replacement: string, postfix: string) {
    const { selectionStart, selectionEnd, value } = this.textarea;

    this.textarea.value =
      value.substr(0, selectionStart) +
      prefix +
      replacement +
      postfix +
      value.substr(selectionEnd, value.length);

    this.textarea.focus({ preventScroll: true });
    this.textarea.setSelectionRange(
      selectionStart + prefix.length,
      selectionStart + prefix.length + replacement.length
    );
  }

  // Sets a new selection range for object
  setSelectionRange(start: number, end: number) {
    if (typeof this.textarea.setSelectionRange !== "undefined") {
      return this.textarea.setSelectionRange(start, end);
    } else {
      return null;
    }
  }

  addButton(name: string, className: string, callback: Decorator) {
    const link = jQuery(
      `<a title="${name}" class="${className}">` +
        `<i class="fa fa-${className}"></i></a>`
    );

    link.click(() => {
      const [prefix, replacement, postfix] = callback(this.getSelection());
      this.replaceSelection(prefix, replacement, postfix);
    });

    jQuery('<li class="button"></li>').append(link).appendTo(this.toolbar);
  }

  addButtons() {
    // Bold button
    // this.addButton("Bold", "bold", s => this.decorator.bold(s));

    // Italic button
    this.addButton("Italics", "italic", (s) => this.decorator.emphasis(s));

    // Heading buttons
    // this.addButton("Heading 2", "header h2", s => this.decorator.h2(s));
    // this.addButton("Heading 3", "header h3", s => this.decorator.h3(s));
    // this.addButton("Heading 4", "header h4", s => this.decorator.h4(s));

    // Block Quote
    // this.addButton("Block Quote", "quote-left", s => this.decorator.blockquote(s))

    // List
    // this.addButton("List", "list-ul", s => this.decorator.list(s));

    // Ordered list
    // this.addButton("Ordered list", "list-ol", s => this.decorator.orderedList(s));

    // Link button
    /*
    this.addButton("Link", "link", selection => {
      let name = selection.length > 0 ? selection : "Link text";
      var url = prompt("Enter link URL", "");
      url = url.length > 0 ? url : "http://example.com/";
      url = url.replace(/^(?!(f|ht)tps?:\/\/)/, 'http://');
      return this.decorator.link(url, name);
    });
    */

    // Email button
    /*
    this.addButton("Email link", "envelope", selection => {
      let name = selection.length > 0 ? selection : "Link text";
      var address = prompt("Enter email address", "");
      address = address.length > 0 ? address : "example@example.com";
      return this.decorator.email(address, name);
    });
    */
  }
}

jQuery(function () {
  jQuery("textarea.rich").each(function () {
    new RichTextArea(this as HTMLTextAreaElement);
  });
});

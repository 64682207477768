import $ from "jquery";

$(function () {
  const itemBoxes = $(".items.list .select-item");
  const checkedItems = () =>
    itemBoxes.get().filter((c: HTMLInputElement) => c.checked);
  const uncheckedItems = () =>
    itemBoxes.get().filter((c: HTMLInputElement) => !c.checked);
  const checkedItemIds = () =>
    checkedItems().map((c) => $(c).data("id") as string);

  // Select all toggle
  $(".item-folder.items.list .select-all").click(function () {
    itemBoxes.prop("checked", uncheckedItems().length > 0);
    return false;
  });

  // Buttons
  $(".move-to-folder-button").click(function () {
    $(".add-to-auction-button").removeClass("expanded");
    $(this).toggleClass("expanded");
    $(".add-to-auction-form").addClass("hidden");
    $(".move-to-folder-form").toggleClass("hidden");
  });

  $(".add-to-auction-button").click(function () {
    $(".move-to-folder-button").removeClass("expanded");
    $(this).toggleClass("expanded");
    $(".move-to-folder-form").addClass("hidden");
    $(".add-to-auction-form").toggleClass("hidden");
  });

  // Forms
  $(".item-action").submit(function () {
    $(this).find(".item-ids").val(checkedItemIds().join(","));
  });

  $(".item-action").addClass("hidden");
});

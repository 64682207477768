import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  initialize() {
    new Dropzone(this.element as HTMLElement, {
      maxThumbnailFilesize: 25,
      acceptedFiles: "image/*"
    });
  }
}

import React, { useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import RichEditor from "./RichEditor";

type Locale = "en" | "nb";

interface Localized<T = Draft.EditorState> {
  en: T;
  nb: T;
}

interface Props {
  boxout: Localized<Draft.RawDraftContentState>;
  catalog_text: Localized<Draft.RawDraftContentState>;
  pull_quote: Localized<Draft.RawDraftContentState>;
  url: string;
  csrf_token: string;
}

function createEditorState(value?: Draft.RawDraftContentState) {
  if (value) {
    return EditorState.createWithContent(convertFromRaw(value));
  } else {
    return EditorState.createEmpty();
  }
}

function prepareLocalizedState(
  attr: Localized<Draft.RawDraftContentState>
): Localized<Draft.EditorState> {
  return {
    en: createEditorState(attr.en),
    nb: createEditorState(attr.nb)
  };
}

export default function CatalogTextEditor(props: Props) {
  const [locale, setLocale] = useState<Locale>("nb");
  const [saved, setSaved] = useState(true);

  const [boxout, setBoxout] = useState(prepareLocalizedState(props.boxout));
  const [catalogText, setCatalogText] = useState(
    prepareLocalizedState(props.catalog_text)
  );
  const [pullQuote, setPullQuote] = useState(
    prepareLocalizedState(props.pull_quote)
  );

  const updateEditorState = (
    currentState: Localized<Draft.EditorState>,
    setState: (state: Localized<Draft.EditorState>) => void,
    nextState: Draft.EditorState
  ) => {
    if (
      nextState.getCurrentContent() != currentState[locale].getCurrentContent()
    ) {
      setSaved(false);
    }
    setState({ ...currentState, [locale]: nextState });
  };

  const updateBoxout = (editorState: Draft.EditorState) => {
    updateEditorState(boxout, setBoxout, editorState);
  };

  const updateCatalogText = (editorState: Draft.EditorState) => {
    updateEditorState(catalogText, setCatalogText, editorState);
  };

  const updatePullQuote = (editorState: Draft.EditorState) => {
    updateEditorState(pullQuote, setPullQuote, editorState);
  };

  const handleLocale = (nextLocale: Locale) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    setLocale(nextLocale);
  };

  const handleSave = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    const data = {
      boxout: {
        nb: convertToRaw(boxout.nb.getCurrentContent()),
        en: convertToRaw(boxout.en.getCurrentContent())
      },
      catalog_text: {
        nb: convertToRaw(catalogText.nb.getCurrentContent()),
        en: convertToRaw(catalogText.en.getCurrentContent())
      },
      pull_quote: {
        nb: convertToRaw(pullQuote.nb.getCurrentContent()),
        en: convertToRaw(pullQuote.en.getCurrentContent())
      }
    };

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", props.url, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", props.csrf_token);
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setSaved(true);
      }
    };
    xhr.send(JSON.stringify({ item: data }));
  };

  return (
    <div className="catalog-text-editor">
      <div className="inner">
        <div className="controls">
          <div className="locale-toggle">
            <button
              className={locale == "nb" ? "active" : ""}
              onClick={handleLocale("nb")}>
              Norsk
            </button>
            <button
              className={locale == "en" ? "active" : ""}
              onClick={handleLocale("en")}>
              Engelsk
            </button>
          </div>
          <button
            className="primary save"
            onClick={handleSave}
            disabled={saved}>
            Lagre
          </button>
        </div>
        <section>
          <h3>Katalogtekst</h3>
          <RichEditor
            editorState={catalogText[locale]}
            onChange={updateCatalogText}
            placeholder="Skriv katalogtekst..."
          />
        </section>
        <section>
          <h3>Faktaboks</h3>
          <RichEditor
            editorState={boxout[locale]}
            onChange={updateBoxout}
            placeholder="Skriv faktaboks..."
          />
        </section>
        <section>
          <h3>Uttrekk</h3>
          <RichEditor
            editorState={pullQuote[locale]}
            onChange={updatePullQuote}
            placeholder="Skriv uttrekk..."
          />
        </section>
      </div>
    </div>
  );
}

import $ from "jquery";

type Folders = string[];

$(function () {
  if (!window.localStorage.openFolders) {
    window.localStorage.openFolders = JSON.stringify([]);
  }

  const openFolders = () =>
    JSON.parse(window.localStorage.openFolders as string) as Folders;
  const isOpen = (id: string) => openFolders().indexOf(id) > -1;

  const setOpen = function (id: string, state: boolean) {
    let folders = openFolders();
    if (state && !isOpen(id)) {
      folders.push(id);
    } else {
      folders = folders.filter((i) => i != id);
    }
    window.localStorage.openFolders = JSON.stringify(folders);
  };

  const toggleOpen = (id: string) => setOpen(id, !isOpen(id));

  $(".item-folders").each(function () {
    const collapsable = $(this).find("> li").get();

    const updateCollapsed = function () {
      $(collapsable).each(function () {
        const id = $(this).closest("li").data("id") as string;
        if (isOpen(id)) {
          $(this).removeClass("collapsed");
        } else {
          $(this).addClass("collapsed");
        }
      });
    };

    $(collapsable)
      .find(".collapse-toggle")
      .click(function () {
        const id = $(this).closest("li").data("id") as string;
        toggleOpen(id);
        updateCollapsed();
      });

    updateCollapsed();
  });
});
